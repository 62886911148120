import { Button, Col, Input, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import { normalizeCpf, normalizePhone } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import { Company } from 'types/Company';
import { EditForm, EditFormItem } from './styles';
import { DatePicker } from 'components/DatePicker';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import { minDateRule } from 'helpers/rules';
import { NaturalPersonForm } from 'modules/escrow/pages/EscrowEdition/CompanyInformation/types';

interface IPersonBasicDataEditProps {
	isOpen: boolean;
	person: Company;
	onClose: () => void;
	onSave: (person: Company) => void;
}
const NaturalPersonBasicDataEdit = ({
	isOpen,
	person,
	onSave,
	onClose,
}: IPersonBasicDataEditProps) => {
	const [form] = EditForm.useForm();

	const onFinish = useCallback(
		(values: NaturalPersonForm) => {
			onSave({
				...person,
				name: values.name,
				taxpayer_id: serializeOnlyNumbers(values.taxpayer_id),
				birth_date: dayjs(values.birth_date).format('YYYY-MM-DD'),
				email_address: values.email_address,
				phone: serializePhoneObject(values.phone),
				mother_name: values.mother_name,
				fund_manager_id: undefined,
				foundation_date: undefined,
				fund_administrator_id: undefined,
				fund_securitizer_id: undefined,
			} as Company);
		},
		[onSave, person],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				name: person.name,
				taxpayer_id: person?.taxpayer_id
					? normalizeCpf(person?.taxpayer_id)
					: '',
				birth_date: dayjs(person.birth_date, 'YYYY-MM-DD'),
				email_address: person.email_address,
				phone: person?.phone
					? formatPhoneFromObject(person?.phone)
					: '',
				mother_name: person.mother_name,
			});
		}
	}, [isOpen, form, person]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Dados básicos"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Salvar
						</Button>
					</Col>
				</Row>
			}
		>
			<EditForm layout="vertical" form={form} onFinish={onFinish}>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="name"
							label="Nome Completo"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: José Silva" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="taxpayer_id"
							label="CPF"
							normalize={e =>
								normalizeCpf(serializeOnlyNumbers(e))
							}
						>
							<Input placeholder="Digite aqui" disabled={true} />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="birth_date"
							label="Data de Nascimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								minDateRule,
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="mother_name"
							label="Nome da mãe"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Maria Silva" />
						</EditFormItem>
					</Col>
				</Row>
			</EditForm>
		</CustomDrawer>
	);
};

export default NaturalPersonBasicDataEdit;
