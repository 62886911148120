import { Flex, Input, notification } from 'antd';
import { Heading1 } from 'components/Heading1';
import { TextS } from 'components/Text';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { validateCnpj, validateCpf } from 'helpers/validators';
import { useCompany } from 'modules/company/hooks';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { useCallback, useEffect, useState } from 'react';
import { PiSpinnerBold } from 'react-icons/pi';
import theme from 'theme/theme';
import TaxReturnedInformation from './TaxReturnedInformation/TaxReturnedInformation';
import { BusinessType, CompanyDto } from 'types/Company';
import BasicInfoComponent from './BasicInfo/BasicInfo';
import ButtonDisableOption from 'components/ButtonDisableOption/ButtonDisableOption';
import { SearchIcon } from 'components/Icons/Search';
import { AiOutlineStop } from 'react-icons/ai';
import CompanyCreationModal from 'modules/company/components/CompanyCreation/CompanyCreation';
import { Spinner } from 'components/UploaderArea/styles';
import { AccountPermission } from 'types/Account';

const InformationComponent = () => {
	const [api, contextHolder] = notification.useNotification();
	const { setCompany, company, resetEscrowContext, setRelatedParts } =
		useEscrowAccountsContext();

	const [warningErrorMessage, setWarningErrorMessage] = useState<string>();
	const [inputValue, setInputValue] = useState<string>();
	const [isOpenNotFoundTaxId, setIsOpenNotFoundTaxId] =
		useState<boolean>(false);
	const [isOpenCreateClientForm, setIsOpenCreateClientForm] =
		useState<boolean>(false);

	const { getCompanyByTaxpayerId, loading } = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message:
					'Ocorreu um problema ao buscar informações da empresa.',
			});
		},
		onSuccess: (newCompany, hasPrevCompany, accounts) => {
			if (newCompany?.id) {
				// Manter comentado por enquanto
				// if (accounts.length >= 2) {
				// 	setRelatedParts([]);
				// 	setCompany({} as CompanyDto);
				// 	return api.error({
				// 		message: 'Limite de contas atingido',
				// 		description:
				// 			'Você atingiu o limite de contas criadas para esta empresa.',
				// 	});
				// }

				setRelatedParts([
					{
						person_id: newCompany.id,
						permission: [AccountPermission.VIEW_ACCOUNT],
						relationship:
							newCompany?.business_type || BusinessType.OTHERS,
						person: newCompany,
						sign_contract: true,
					},
				]);
				return setCompany(newCompany);
			}

			setCompany({ ...({} as CompanyDto), taxpayer_id: inputValue });
			setRelatedParts([]);
			setIsOpenNotFoundTaxId(true);
		},
	});

	const isLoading = loading;
	const isAbleToSearch =
		serializeOnlyNumbers(inputValue).length === 11 ||
		serializeOnlyNumbers(inputValue).length === 14;

	const onSearchHandler = useCallback(() => {
		const isPJ: boolean = serializeOnlyNumbers(inputValue).length === 14;
		const isPF: boolean = serializeOnlyNumbers(inputValue).length === 11;

		if (isPF && !validateCpf(inputValue || '')) {
			setWarningErrorMessage('CPF inválido');
			setCompany({} as CompanyDto);
			return;
		}

		if (isPJ && !validateCnpj(inputValue || '')) {
			setWarningErrorMessage('CNPJ inválido');
			setCompany({} as CompanyDto);
			return;
		}

		if (isPJ && validateCnpj(inputValue || '') && !isLoading) {
			getCompanyByTaxpayerId(serializeOnlyNumbers(inputValue));
		}

		if (isPF && validateCpf(inputValue || '') && !isLoading) {
			getCompanyByTaxpayerId(serializeOnlyNumbers(inputValue));
		}

		setWarningErrorMessage(undefined);
	}, [inputValue]);

	const onClickCreateNewClient = () => {
		setIsOpenCreateClientForm(true);
		setIsOpenNotFoundTaxId(false);
	};

	useEffect(() => {
		if (inputValue?.length === 1) {
			setCompany({} as CompanyDto);
			setRelatedParts([]);
			setIsOpenNotFoundTaxId(false);
		}
	}, [inputValue]);

	return (
		<>
			{contextHolder}
			<Heading1>Para qual cliente você deseja criar a conta? </Heading1>
			<Flex align="flex-end" justify="space-between">
				<Flex vertical style={{ width: '100%' }}>
					<TextS
						color={theme.textSecondary}
						style={{ paddingBottom: '10px' }}
					>
						Digite o CPF / CNPJ do cliente:
					</TextS>
					<Input
						disabled={isLoading}
						placeholder="Digite aqui"
						value={normalizeCpfCnpj(inputValue || '-')}
						onChange={e => setInputValue(e.target.value)}
						status={
							!!warningErrorMessage?.length ? 'error' : undefined
						}
						suffix={
							isLoading ? (
								<Spinner>
									<PiSpinnerBold
										style={{
											fontSize: 24,
										}}
									/>
								</Spinner>
							) : null
						}
					/>
				</Flex>
				<Flex style={{ margin: '0 1.5rem' }}>
					<ButtonDisableOption
						disable={!isAbleToSearch}
						variant="primary"
						onClick={onSearchHandler}
						text="Buscar"
						icon={
							<SearchIcon
								size="18"
								color={
									!isAbleToSearch
										? theme.primary
										: theme.white
								}
							/>
						}
					/>
				</Flex>
				<Flex>
					<ButtonDisableOption
						disable={!inputValue?.length}
						variant="secondary"
						text="Limpar busca"
						icon={<AiOutlineStop size={18} color={theme.primary} />}
						onClick={() => {
							setInputValue(undefined);
							setCompany({} as CompanyDto);
							setRelatedParts([]);
						}}
					/>
				</Flex>
			</Flex>

			{!!warningErrorMessage?.length && (
				<TextS color={theme.dangerText}>{warningErrorMessage}</TextS>
			)}

			{/* Content Info  */}
			{company?.id && <BasicInfoComponent />}

			{/* Not fount taxId warning and option to create client  */}
			{isOpenNotFoundTaxId && (
				<TaxReturnedInformation
					onClickHandler={onClickCreateNewClient}
				/>
			)}

			{/* Modal de criação de cliente */}
			{isOpenCreateClientForm && (
				<CompanyCreationModal
					isOpen={!!isOpenCreateClientForm}
					onFinishHandler={() => setIsOpenCreateClientForm(false)}
					isAccountCreation={true}
					onCloseHandler={() => {
						setIsOpenCreateClientForm(false);
						resetEscrowContext();
					}}
				/>
			)}
		</>
	);
};

export default InformationComponent;
