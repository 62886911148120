import { useCallback, useState } from 'react';
import { ApiError } from 'types/ApiError';
import {
	AutoCompleteInfoParams,
	AutoCompletePFInfo,
	AutoCompletePJInfo,
} from 'types/Company';
import { PersonsService } from '../services';

export const useAutoCompletePersonData = (
	options?: {
		onError?: (error: ApiError) => void;
		onSuccess?: (
			personData: AutoCompletePFInfo | AutoCompletePJInfo,
		) => void;
	},
	listeners?: unknown[],
) => {
	const [isLoading, setIsLoading] = useState(false);
	const getPersonData = useCallback(
		async (params: AutoCompleteInfoParams) => {
			try {
				setIsLoading(true);
				const personData = await PersonsService.getAutoComplete(params);
				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(personData);
				}
				return personData;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[...(listeners || [])],
	);
	return { isLoading, getPersonData };
};
