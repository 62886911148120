import { Button, Col, Input, Row, Select, Spin, notification } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { normalizeCep } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { Company } from 'types/Company';
import {
	EditForm,
	EditFormItem,
} from '../../../modules/management/pages/ClientsList/CompanyDetails/NaturalPerson/NaturalPersonEdit/styles';
import { statesList } from 'constants/states';
import axios from 'axios';
import { Address, AddressViaCep } from 'types/Address';

interface IAddressModalComponentProps {
	isOpen: boolean;
	person: Company;
	onClose: () => void;
	onSave: (person: Company) => void;
}
const AddressModalComponent = ({
	isOpen,
	person,
	onSave,
	onClose,
}: IAddressModalComponentProps) => {
	/* Hooks */
	const [form] = EditForm.useForm();
	const [api, contextHolder] = notification.useNotification();

	/* States */
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [address, setAddress] = useState<Address>({
		city: person.address?.city,
		country_code: person.address?.country_code,
		district: person.address?.district,
		extra_info: person.address?.extra_info,
		postal_code: person.address?.postal_code,
		state_code: person.address?.state_code,
		street_name: person.address?.street_name,
		street_number: person.address?.street_number,
	});

	const getAddress = async (cep: string) => {
		const clearedCep = cep.replace(/\D/g, '');

		try {
			setIsLoading(true);
			const response = await axios.get<AddressViaCep>(
				`https://viacep.com.br/ws/${clearedCep}/json/`,
			);

			if (!response.data.logradouro) {
				setAddress({} as Address);
				form.setFieldsValue({
					city: '',
					district: '',
					state_code: undefined,
					street_name: '',
					street_number: '',
				});
				return api.error({
					description:
						'Verifique as informações e preencha o formulário.',
					message: 'Cep não encontrado.',
				});
			}

			const viaCepAddress = response.data as AddressViaCep;

			setAddress({
				...address,
				city: viaCepAddress?.localidade || '',
				country_code: 'BRZ',
				district: viaCepAddress?.bairro,
				state_code: viaCepAddress?.uf,
				street_name: viaCepAddress?.logradouro,
				postal_code: serializeOnlyNumbers(viaCepAddress?.cep),
			});

			form.setFieldsValue({
				city: viaCepAddress.localidade || '',
				district: viaCepAddress.bairro || '',
				state_code: viaCepAddress.uf || '',
				street_name: viaCepAddress.logradouro || '',
			});
		} catch (error) {
			setAddress({} as Address);
			form.setFieldsValue({
				city: '',
				district: '',
				state_code: undefined,
				street_name: '',
				street_number: '',
			});

			api.error({
				description:
					'Verifique as informações e preencha o formulário.',
				message: 'Cep não encontrado.',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const onFinish = useCallback(() => {
		onSave({
			...person,
			address: {
				postal_code: serializeOnlyNumbers(address.postal_code),
				state_code: address.state_code,
				city: address.city,
				district: address.district,
				street_name: address.street_name,
				street_number: address.street_number,
				extra_info: address.extra_info,
				country_code: 'BRZ',
			},
		} as Company);
	}, [onSave, person, address]);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				postal_code: person?.address?.postal_code
					? normalizeCep(person?.address?.postal_code)
					: '',
				state_code: person?.address?.state_code,
				city: person?.address?.city,
				district: person?.address?.district,
				street_name: person?.address?.street_name,
				street_number: person?.address?.street_number,
				extra_info: person?.address?.extra_info,
			});
		}
	}, [isOpen, form, person]);

	return (
		<>
			{contextHolder}

			{isLoading && <Spin fullscreen />}

			<CustomDrawer
				onClose={onClose}
				open={isOpen}
				title="Endereço"
				footer={
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<Button
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									justifyContent: 'center',
									position: 'relative',
								}}
								size="large"
								type="primary"
								onClick={form.submit}
							>
								Salvar
							</Button>
						</Col>
					</Row>
				}
			>
				<EditForm
					layout="vertical"
					form={form}
					onFinish={onFinish}
					onFieldsChange={f => {
						setAddress({ ...address, [f[0].name]: f[0].value });
					}}
				>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="postal_code"
								label="CEP"
								normalize={e => normalizeCep(e)}
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input
									placeholder="00000-000"
									maxLength={9}
									onChange={e =>
										e.target.value.length === 9
											? getAddress(e.target.value)
											: undefined
									}
								/>
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="state_code"
								label="Estado"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Select
									showSearch={true}
									filterOption={(input, option) =>
										option?.label
											?.toLowerCase()
											?.startsWith(input.toLowerCase()) ||
										String(option?.value)
											?.toLowerCase()
											?.startsWith(input.toLowerCase())
									}
									placeholder="Selecione o estado"
									options={statesList}
								/>
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="city"
								label="Cidade"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: São Paulo" />
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="district"
								label="Bairro"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: Planalto do Sol" />
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="street_name"
								label="Rua/Avenida"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: Av Presidente Vargas" />
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem
								name="street_number"
								label="Número"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
								]}
							>
								<Input placeholder="Ex.: 300" />
							</EditFormItem>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{ width: '100%' }}
						align="middle"
					>
						<Col span={24}>
							<EditFormItem name="extra_info" label="Complemento">
								<Input placeholder="Ex.: Próximo a escola..." />
							</EditFormItem>
						</Col>
					</Row>
				</EditForm>
			</CustomDrawer>
		</>
	);
};

export default AddressModalComponent;
