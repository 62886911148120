import { Button, Flex, Radio, Space } from 'antd';
import { Heading2 } from 'components/Heading2';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { ETenantType } from 'types/Company';

interface ITenantTypeModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (tenantType?: ETenantType) => void;
	tenantType?: ETenantType;
	setTenantType: (tenantType: ETenantType) => void;
}

const TenantTypeModal = ({
	tenantType,
	isOpen,
	onClose,
	onSave,
	setTenantType,
}: ITenantTypeModalProps) => {
	return (
		<Modal
			isOpen={isOpen}
			title="Configurações da conta"
			width={500}
			height={300}
			onClose={onClose}
		>
			<Heading2>Tipo de tenant</Heading2>
			<br />
			<Radio.Group
				onChange={e => setTenantType(e.target.value)}
				value={tenantType}
			>
				<Space direction="vertical">
					<Radio
						value={ETenantType.DEFAULT}
						style={{ marginBottom: '1rem' }}
					>
						Escrow
					</Radio>
					<Radio
						value={ETenantType.PRIME}
						style={{ marginBottom: '1rem' }}
					>
						Prime
					</Radio>
				</Space>
			</Radio.Group>
			<Flex vertical>
				<Button
					onClick={() => onSave(tenantType)}
					type="primary"
					style={{ marginTop: '2rem', padding: '1rem' }}
				>
					<TextS color={theme.white}>Salvar alterações</TextS>
				</Button>
				<Button
					onClick={onClose}
					type="text"
					style={{ marginTop: '1rem' }}
				>
					<TextS color={theme.primary}>Cancelar</TextS>
				</Button>
			</Flex>
		</Modal>
	);
};

export default TenantTypeModal;
