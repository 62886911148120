import { useCallback, useMemo, useState } from 'react';
import { GoLaw } from 'react-icons/go';
import { queryClient } from 'network/query';
import dayjs from 'dayjs';
import { IoPeopleOutline } from 'react-icons/io5';
import {
	Button,
	Col,
	CollapseProps,
	Descriptions,
	DescriptionsProps,
	Empty,
	Flex,
	Row,
} from 'antd';

// Components
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { Documents } from 'components/Documents';
import { NaturalPersonRepresentativesCreation } from 'components/NaturalPersonRepresentativesCreation';
import { PageActions } from 'components/PageActions';
import { TextM, TextS } from 'components/Text';
import EmptyRepresentativesList from 'components/images/emptyListA.png';

// Hooks and services
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { useCompany } from 'modules/company/hooks';

// Types and helpers
import { formatPhoneFromObject } from 'helpers/formaters';
import { normalizeCpf } from 'helpers/normalizers';
import { Company } from 'types/Company';
import {
	BaseRepresentative,
	Representative,
	RepresentativeDocuments,
} from 'types/Representative';
import {
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';

// Styles
import theme from 'theme/theme';
import { ContainerFluid, ContainerHeader, DescriptionItem } from './styles';
import { Heading1 } from 'components/Heading1';

export interface CustomDescriptionItemProps extends IChildrenProps {}

export const CustomDescriptionItem = ({
	children,
}: CustomDescriptionItemProps) => {
	return (
		<DescriptionItem style={{ margin: '0', padding: '0.5rem 0' }}>
			{children}
		</DescriptionItem>
	);
};

const createRepresentativeBasicDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	const style = {
		borderBottom: '1px solid #f0f0f0',
	};

	return [
		{
			key: '1',
			label: 'CPF',
			style,
			children: (
				<CustomDescriptionItem>
					{normalizeCpf(fullRepresentative.taxpayer_id)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Nome completo',
			style,
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.full_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Data de nascimento',
			style,
			children: (
				<CustomDescriptionItem>
					{dayjs(fullRepresentative.birth_date, 'YYYY-MM-DD').format(
						'DD/MM/YYYY',
					)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Nome da mãe',
			style,
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.mother_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'E-mail',
			style,
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.email_address}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Telefone',
			style,
			children: (
				<CustomDescriptionItem>
					{typeof fullRepresentative.phone === 'string'
						? fullRepresentative.phone
						: formatPhoneFromObject(fullRepresentative.phone)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Cargo',
			style,
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.role
						? RepresentativeRoleDescription[fullRepresentative.role]
						: '-'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '9',
			label: 'Assina contrato',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.sign_contract ? 'Sim' : 'Não'}
				</CustomDescriptionItem>
			),
		},
	];
};

const createRepresentativeIdentificationDescriptionItems = (
	fullRepresentative?: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'Documento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative?.id_document?.type}:{' '}
					{fullRepresentative?.id_document?.number}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Data de emissão',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{dayjs(
						fullRepresentative?.id_document?.issue_date,
						'YYYY-MM-DD',
					).format('DD/MM/YYYY')}
				</CustomDescriptionItem>
			),
		},
		{
			key: '3',
			label: 'Órgão emissor',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative?.id_document?.issuer}
				</CustomDescriptionItem>
			),
		},
	];
};

const createRepresentativeAddressDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'CEP',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.postal_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '2',
			label: 'Rua / Avenida',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '3',
			label: 'Número',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_number}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Estado',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.state_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Cidade',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.city}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Bairro',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.district}
				</CustomDescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Complemento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.extra_info ?? '-'}
				</CustomDescriptionItem>
			),
		},
	];
};

interface IPersonRepresentativesProps {
	isAccountCreation?: boolean;
	isClientEdition?: boolean;
	person?: Company;
}
const PersonRepresentatives = ({
	isAccountCreation,
	isClientEdition,
	person,
}: IPersonRepresentativesProps) => {
	/* Hooks */
	const { updateRepresentative, createRepresentative } = useCompany();
	const { setCompany } = useEscrowAccountsContext();

	/* States */
	const [representativeToEdit, setRepresentativeToEdit] =
		useState<Representative>();
	const [isRepresentativesCreationOpen, setIsRepresentativesCreationOpen] =
		useState(false);
	const [isMissingRepresentatives, setIsMissingRepresentatives] =
		useState(false);
	const [isMissingDocuments, setIsMissingDocuments] = useState(false);
	const [isMissingSigners, setIsMissingSigners] = useState(false);

	const isNewCompany = useMemo(() => !person?.id, [person]);

	const onModifyRepresentative = useCallback(
		async (representative: Representative) => {
			const representatives: Representative[] =
				person?.representatives || [];

			if (representatives.length > 0) {
				const missingDocuments = representatives.some(r => {
					if (
						!(
							(typeof r.documents['identification'] ===
								'string' &&
								typeof r.documents['proof_of_address'] ===
									'string') ||
							(typeof r.documents['identification_url'] ===
								'string' &&
								typeof r.documents['proof_of_address_url'] ===
									'string')
						)
					) {
						return true;
					}

					if (
						r.representative &&
						!(
							(typeof r.representative.documents[
								'identification'
							] === 'string' &&
								typeof r.representative.documents[
									'proof_of_address'
								] === 'string') ||
							(typeof r.representative.documents[
								'identification_url'
							] === 'string' &&
								typeof r.representative.documents[
									'proof_of_address_url'
								] === 'string')
						)
					) {
						return true;
					}

					return false;
				});

				if (missingDocuments) {
					setIsMissingDocuments(true);
					return;
				}

				const missingSigners = representatives.filter(r => {
					if (r.sign_contract) return true;
					if (r.representative && r.representative.sign_contract)
						return true;
					return false;
				});

				if (
					missingSigners.length === 0 &&
					!representative.sign_contract
				) {
					setIsMissingSigners(true);
					return;
				}
			}

			if (!representativeToEdit) {
				if (person?.id) {
					const response = await createRepresentative(
						person.id,
						representative,
					);

					if (response) {
						setCompany({
							...person,
							representatives: [
								...representatives,
								{ ...representative, id: response.id },
							],
						});
					}
				} else {
					setCompany({
						...person,
						representatives: [...representatives, representative],
					});
				}
			} else {
				if (person?.id) {
					await updateRepresentative(person.id, representative);
				}

				setCompany({
					...person,
					representatives: representatives.map(r => {
						if (
							r.taxpayer_id === representativeToEdit.taxpayer_id
						) {
							return {
								...r,
								...representative,
							};
						}
						return r;
					}),
				});
				setRepresentativeToEdit(undefined);
			}

			queryClient.refetchQueries({
				queryKey: ['companyDetails', person?.id],
			});

			setIsRepresentativesCreationOpen(false);
			setIsMissingRepresentatives(false);
			setIsMissingDocuments(false);
		},
		[
			representativeToEdit,
			person,
			createRepresentative,
			updateRepresentative,
		],
	);

	const onEditRepresentative = useCallback(
		(representative: BaseRepresentative) => {
			setRepresentativeToEdit(representative);
			setIsRepresentativesCreationOpen(true);
		},
		[],
	);

	const onRemoveRepresentative = useCallback(
		(representative: Representative) => {
			setCompany({
				...person,
				representatives: person?.representatives?.filter(
					r => r.taxpayer_id !== representative.taxpayer_id,
				),
			});
		},
		[person],
	);

	const onRemoveLegalRepresentative = useCallback(
		(representative: Representative) => {
			setCompany({
				...person,
				representatives: person?.representatives?.map(r => {
					if (r.taxpayer_id === representative.taxpayer_id) {
						return {
							...r,
							representative: undefined,
						};
					}
					return r;
				}),
			});
		},
		[person],
	);

	const onUpdateDocument = useCallback(
		(
			representative: Representative,
			documents: RepresentativeDocuments,
			legal_representative?: Representative,
		) => {
			setCompany({
				...person,
				representatives: person?.representatives?.map(r => {
					if (r.taxpayer_id === representative.taxpayer_id) {
						if (typeof legal_representative === 'undefined') {
							return {
								...r,
								documents,
							};
						} else {
							return {
								...r,
								representative: {
									...r.representative!,
									documents,
								},
							};
						}
					}
					return r;
				}),
			});
		},
		[person],
	);

	const representativesItems: CollapseProps['items'] = useMemo(
		() =>
			person?.representatives?.map((representative, i) => {
				const legalRepresentativesItems:
					| CollapseProps['items']
					| undefined =
					representative?.representative &&
					[representative.representative].map(
						(legal_representative, j) => {
							return {
								key: `${j + 1}`,
								label: (
									<CollapseHeader>
										<GoLaw
											color={theme.primary}
											size="24"
										/>{' '}
										<TextM>
											{legal_representative.full_name}
										</TextM>
									</CollapseHeader>
								),
								children: (
									<>
										<Row gutter={[16, 16]}>
											<Col>
												<Descriptions
													layout="vertical"
													column={4}
													items={createRepresentativeBasicDescriptionItems(
														legal_representative,
													)}
												/>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col>
												<TextM>Documentos</TextM>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ paddingBottom: '1rem' }}
										>
											<Col span={24}>
												<Documents
													hideTitle={true}
													isDisabled={!isNewCompany}
													onChangeDocumentsObject={documents => {
														onUpdateDocument(
															representative,
															documents,
															legal_representative,
														);
													}}
													documentsObject={
														legal_representative.documents
													}
													documentsList={
														RepresentativeDocumentsList
													}
												/>
											</Col>
										</Row>
										{(!isAccountCreation ||
											isNewCompany) && (
											<Row
												gutter={[16, 16]}
												justify="end"
											>
												<Col>
													<Button
														type="link"
														onClick={() =>
															onEditRepresentative(
																representative,
															)
														}
													>
														Editar
													</Button>
												</Col>
												<Col>
													<Button
														type="link"
														danger={true}
														onClick={() =>
															onRemoveLegalRepresentative(
																representative,
															)
														}
													>
														Remover
													</Button>
												</Col>
											</Row>
										)}
									</>
								),
							};
						},
					);

				return {
					key: `${i + 1}`,
					label: (
						<CollapseHeader>
							<IoPeopleOutline color={theme.primary} size="24" />{' '}
							<TextM>{representative.full_name}</TextM>
						</CollapseHeader>
					),
					children: (
						<>
							<Row gutter={[16, 16]}>
								<Col>
									<TextM>Informações básicas</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeBasicDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>

							<Row
								gutter={[16, 16]}
								style={{ marginTop: '2rem' }}
							>
								<Col>
									<TextM>Identificação</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeIdentificationDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>

							<Row
								gutter={[16, 16]}
								style={{ marginTop: '2rem' }}
							>
								<Col>
									<TextM>Endereço</TextM>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeAddressDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{
									marginBottom: '1rem',
									marginTop: '2rem',
								}}
							>
								<Col>
									<TextM>Documentos</TextM>
								</Col>
							</Row>
							<Row
								gutter={[16, 16]}
								style={{ paddingBottom: '1rem' }}
							>
								<Col span={24}>
									<Documents
										hideTitle={true}
										isDisabled={!isNewCompany}
										onChangeDocumentsObject={documents => {
											onUpdateDocument(
												representative,
												documents,
											);
										}}
										documentsObject={
											representative.documents
										}
										documentsList={
											RepresentativeDocumentsList
										}
									/>
								</Col>
							</Row>
							{legalRepresentativesItems &&
								legalRepresentativesItems.length > 0 && (
									<>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col>
												<TextM>Procuradores</TextM>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col span={24}>
												<Collapse
													expandIconPosition="end"
													items={
														legalRepresentativesItems
													}
												/>
											</Col>
										</Row>
									</>
								)}
							{(!isAccountCreation || isNewCompany) && (
								<Row gutter={[16, 16]} justify="end">
									<Col>
										<Button
											type="link"
											onClick={() =>
												onEditRepresentative(
													representative,
												)
											}
										>
											Editar
										</Button>
									</Col>

									{isAccountCreation && (
										<Col>
											<Button
												type="link"
												danger={true}
												onClick={() =>
													onRemoveRepresentative(
														representative,
													)
												}
											>
												Remover
											</Button>
										</Col>
									)}
								</Row>
							)}
						</>
					),
				};
			}),
		[
			isAccountCreation,
			isNewCompany,
			onEditRepresentative,
			onRemoveLegalRepresentative,
			onRemoveRepresentative,
			onUpdateDocument,
		],
	);

	return (
		<ContainerFluid>
			{(isAccountCreation ||
				(isClientEdition && !!person?.representatives?.length)) && (
				<ContainerHeader>
					<Heading1
						style={{
							marginBottom: '2.5rem',
						}}
					>
						{/* Esta tela é usada em criação de conta e para edição de cliente.
						 * Em edição, não deverá apresentar o title, apenas o button */}
						{!isClientEdition && 'Procurador'}
					</Heading1>
					{!isAccountCreation && (
						<PageActions>
							<Button
								type="primary"
								onClick={() =>
									setIsRepresentativesCreationOpen(true)
								}
							>
								<Flex
									style={{
										alignItems: 'center',
									}}
								>
									<TextS color={theme.white}>
										Adicionar procurador
									</TextS>
									<AddCircleIcon
										color={theme.white}
										size="18"
									/>
								</Flex>
							</Button>
						</PageActions>
					)}
				</ContainerHeader>
			)}
			{!person?.representatives?.length && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						Não há procuradores adicionados
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						image={EmptyRepresentativesList}
						imageStyle={{
							height: 200,
						}}
						/* Está com fragment para ele não renderizar "Não há dados" */
						description={<></>}
					/>

					{!person?.representatives?.length && !isAccountCreation && (
						<Flex justify="center">
							<Button
								type="primary"
								onClick={() =>
									setIsRepresentativesCreationOpen(true)
								}
							>
								<Flex
									style={{
										alignItems: 'center',
									}}
								>
									<TextS
										color={theme.white}
										style={{ paddingRight: '10px' }}
									>
										Adicionar procurador
									</TextS>
									<AddCircleIcon
										color={theme.white}
										size="18"
									/>
								</Flex>
							</Button>
						</Flex>
					)}
				</>
			)}
			{person?.representatives?.length !== 0 && (
				<Collapse
					expandIconPosition="end"
					items={representativesItems}
				/>
			)}
			{isMissingDocuments && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* Verifique todos os documentos obrigatórios para os
					representantes e procuradores.
				</TextS>
			)}
			{isMissingRepresentatives && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes.
				</TextS>
			)}
			{isMissingSigners && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes
					assinantes.
				</TextS>
			)}

			{/* Modals  */}
			<NaturalPersonRepresentativesCreation
				isOpen={isRepresentativesCreationOpen}
				representative={representativeToEdit}
				isEdition={!!representativeToEdit}
				onCreate={onModifyRepresentative}
				onClose={() => {
					setIsRepresentativesCreationOpen(false);
					setRepresentativeToEdit(undefined);
				}}
			/>
		</ContainerFluid>
	);
};

export default PersonRepresentatives;
