import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { Address } from 'types/Address';
import { BusinessType, CompanyDocuments, CompanyDto } from 'types/Company';
import { IdDocument } from 'types/IdDocument';
import {
	BaseRepresentative,
	RepresentativeDocuments,
} from 'types/Representative';

export function validateAddressForm(): boolean {
	const { company } = useEscrowAccountsContext();
	const address = company?.address;

	if (!address) {
		return false;
	}

	const requiredFields = [
		'street_name',
		'street_number',
		'postal_code',
		'district',
		'city',
		'state_code',
	];

	return requiredFields.every(field => address[field as keyof Address]);
}

export function validateInformationForm(): boolean {
	const { company } = useEscrowAccountsContext();

	if (!company) {
		return false;
	}

	const requiredFields = [
		'birth_date',
		'email_address',
		'mother_name',
		'name',
		'phone',
		'taxpayer_id',
	];

	return requiredFields.every(field => company[field as keyof CompanyDto]);
}

export function validateLegalInformationForm(): boolean {
	const { company } = useEscrowAccountsContext();

	if (!company) {
		return false;
	}

	const requiredFields = [
		'taxpayer_id',
		'name',
		'phone',
		'corporate_name',
		'foundation_date',
		'email_address',
		'cnae',
		'average_monthly_revenue',
	];

	if (company.business_type === BusinessType.FUND) {
		requiredFields.push('fund_administrator_id');
		requiredFields.push('fund_manager_id');
		requiredFields.push('fund_securitizer_id');
	}

	return requiredFields.every(field => company[field as keyof CompanyDto]);
}

export function validateDocumentForm(documents?: CompanyDocuments): boolean {
	if (!documents) {
		return false;
	}

	const requiredFields = [
		'identification',
		'identification_url',
		'proof_of_address',
		'proof_of_address_url',
	];

	return requiredFields.every(
		field => documents[field as keyof CompanyDocuments],
	);
}

export function validateLegalDocumentForm(
	documents?: CompanyDocuments,
): boolean {
	if (!documents) {
		return false;
	}

	const requiredFields = [
		'income_statement',
		'income_statement_url',
		'incorporation_certificate',
		'incorporation_certificate_url',
		'last_contract_amendment',
		'last_contract_amendment_url',
		'proof_of_address',
		'proof_of_address_url',
	];

	return requiredFields.every(
		field => documents[field as keyof CompanyDocuments],
	);
}

export function validateRepresentativeForm(): boolean {
	const { representative } = useEscrowAccountsContext();
	const address = representative?.address;
	const documents = representative?.documents;
	const idDocument = representative?.id_document;

	if (!representative || !address || !documents || !idDocument) {
		return false;
	}

	const idDocumentFields = ['issue_date', 'issuer', 'number', 'type'];
	const addressRequiredFields = [
		'street_name',
		'street_number',
		'postal_code',
		'district',
		'city',
		'state_code',
	];
	const docsRequiredFields = [
		'identification',
		'identification_url',
		'proof_of_address',
		'proof_of_address_url',
	];
	const requiredFields = [
		'birth_date',
		'email_address',
		'full_name',
		'mother_name',
		'name',
		'phone',
		'role',
		'taxpayer_id',
	];

	const isAddressAble = addressRequiredFields.every(
		field => address[field as keyof Address],
	);
	const isDocumentsAble = docsRequiredFields.every(
		field => documents[field as keyof RepresentativeDocuments],
	);
	const isRequiredFieldsAble = requiredFields.every(
		field => representative[field as keyof BaseRepresentative],
	);
	const isIdFieldsAble = idDocumentFields.every(
		field => idDocument[field as keyof IdDocument],
	);
	const isSelectorsAble =
		representative.pep !== undefined &&
		representative.sign_contract !== undefined;

	return (
		isAddressAble &&
		isRequiredFieldsAble &&
		isDocumentsAble &&
		isIdFieldsAble &&
		isSelectorsAble
	);
}
