import { Button, Empty, notification } from 'antd';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
	ButtonActions,
	ContainerFluid,
	ContainerHeader,
	Footer,
} from './styles';
import { ColumnsType } from 'antd/es/table';
import { GoTrash } from 'react-icons/go';
import { CiEdit } from 'react-icons/ci';
import { Destination, DestinationType } from 'types/Destination';
import { TextL, TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { BankListDescription } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { DestinationCreation } from 'modules/escrow/components/DestinationCreation';
import { useDestinations } from 'modules/escrow/hooks';
import { Table } from 'components/Table';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Heading2 } from 'components/Heading2';
import { Account, AccountStatus } from 'types/Account';

interface IAccountDestinationProps {
	account: Account;
	destinations?: Destination[];
	renderFooter?: (destinations: Destination[]) => ReactNode;
}
const AccountDestination = ({
	account,
	destinations: editDestinations,
	renderFooter,
}: IAccountDestinationProps) => {
	const theme = useTheme();
	const { type, permissions } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const accountId = account.id;
	const isAbleToCreate =
		account.status === AccountStatus.AWAITING_BACKOFFICE_ANALYSIS;

	const [destinations, setDestinations] = useState<Destination[]>([]);
	const [editDestination, setEditDestination] = useState<Destination>();
	const [destinationToConfirm, setDestinationToConfirm] =
		useState<Destination>();
	const [isDestinationConfirmOpen, setIsDestinationConfirmOpen] =
		useState(false);
	const [isDestinationRemoveOpen, setIsDestinationRemoveOpen] =
		useState(false);
	const [isDestinationCreationOpen, setIsDestinationCreationOpen] =
		useState(false);

	const isAbleToCreateBeneficiary =
		type === AccessType.FINANCIAL_INSTITUTION ||
		(permissions.includes(AccessPermission.CREATE_BENEFICIARY) &&
			isAbleToCreate);

	useEffect(() => {
		if (Array.isArray(editDestinations) && editDestinations.length > 0) {
			setDestinations(editDestinations);
		}
	}, [editDestinations]);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(accountId, {
			onCreateSuccess: destination => {
				setDestinations(prev => [...prev, destination]);
				setIsDestinationConfirmOpen(false);
				setDestinationToConfirm(undefined);
			},
			onUpdateSuccess: destination => {
				setEditDestination(undefined);
				setDestinations(prev =>
					prev.map(prevDestination =>
						prevDestination.id === destination.id
							? destination
							: prevDestination,
					),
				);
				setIsDestinationConfirmOpen(false);
				setDestinationToConfirm(undefined);
			},
			onDeleteSuccess: destinationId => {
				setDestinations(prevDestinations =>
					prevDestinations.filter(
						destination => destination.id !== destinationId,
					),
				);
				setIsDestinationRemoveOpen(false);
				setDestinationToConfirm(undefined);
			},
			onError: error => {
				setEditDestination(undefined);
				api.error({
					description: error.data.message,
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	const onEditDestination = useCallback((destination: Destination) => {
		setEditDestination(destination);
		setIsDestinationCreationOpen(true);
	}, []);

	const onCreateDestination = useCallback(
		(newDestination: Destination) => {
			if (editDestination) {
				createOrUpdateDestination({
					...editDestination,
					...newDestination,
				});
			} else {
				createOrUpdateDestination(newDestination);
			}
		},
		[createOrUpdateDestination, editDestination],
	);

	const onCloseDestinationCreation = useCallback(() => {
		setEditDestination(undefined);
		setIsDestinationCreationOpen(false);
	}, []);

	const columns: ColumnsType<Destination> = useMemo(() => {
		const items: ColumnsType<Destination> = [
			{
				title: 'Títular da conta',
				width: '25%',
				render: (destination: Destination) => {
					return (
						<div>
							<TextM weight="normal">{`${destination.name}`}</TextM>
							<TextS color={theme.textSecondary}>
								{normalizeCpfCnpj(destination.taxpayer_id)}
							</TextS>
						</div>
					);
				},
			},
			{
				title: 'Banco',
				width: '20%',
				render: (destination: Destination) => (
					<TextM weight="normal">{`${
						BankListDescription[destination.bank]
					}`}</TextM>
				),
			},
			{
				title: 'Agência',
				width: '10%',
				render: (destination: Destination) => (
					<TextM weight="normal">{`${destination.branch}`}</TextM>
				),
			},
			{
				title: 'Conta',
				width: '10%',
				render: (destination: Destination) => (
					<TextM weight="normal">{`${normalizeBankAccount(
						destination.account,
					)}`}</TextM>
				),
			},
			{
				title: 'Tipo de conta',
				width: '20%',
				render: (destination: Destination) => (
					<TextM weight="normal">{`${
						DestinationTypeDescription[
							destination.type as DestinationType
						]
					}`}</TextM>
				),
			},
		];

		if (type === AccessType.FINANCIAL_INSTITUTION) {
			items.push({
				title: 'Ações',
				align: 'center',
				render: (destination: Destination) => (
					<ButtonActions>
						<Button
							type="text"
							disabled={loading}
							onClick={() => onEditDestination(destination)}
						>
							<CiEdit />
						</Button>
						<Button
							type="text"
							disabled={loading}
							onClick={() => {
								setDestinationToConfirm(destination);
								setIsDestinationRemoveOpen(true);
							}}
						>
							<GoTrash />
						</Button>
					</ButtonActions>
				),
			});
		}

		return items;
	}, [loading, onEditDestination, theme, type]);

	return (
		<ContainerFluid>
			{contextHolder}
			<ContainerHeader>
				<Heading2>Contas Beneficiárias</Heading2>
				{destinations.length > 0 && isAbleToCreateBeneficiary && (
					<Button
						type="primary"
						onClick={() => setIsDestinationCreationOpen(true)}
					>
						Adicionar
					</Button>
				)}
			</ContainerHeader>
			{destinations.length === 0 && (
				<Empty
					description="Nenhuma conta beneficiária adicionada"
					image={null}
					style={{
						paddingBottom: '5rem',
						border: `1px solid ${theme.border}`,
						borderRadius: '10px',
					}}
				>
					{isAbleToCreateBeneficiary && (
						<Button
							type="primary"
							onClick={() => setIsDestinationCreationOpen(true)}
						>
							Adicionar
						</Button>
					)}
				</Empty>
			)}
			{destinations.length > 0 && (
				<Table
					columns={columns}
					rowKey={record => record.taxpayer_id}
					dataSource={destinations}
					pagination={false}
				/>
			)}
			{typeof renderFooter === 'function' && (
				<Footer>{renderFooter(destinations)}</Footer>
			)}
			<DestinationCreation
				isOpen={isDestinationCreationOpen}
				destination={editDestination}
				isLoading={loading}
				onClose={onCloseDestinationCreation}
				onCreate={destination => {
					setDestinationToConfirm(destination);
					setIsDestinationCreationOpen(false);
					setIsDestinationConfirmOpen(true);
				}}
			/>
			<ConfirmationModal
				isOpen={isDestinationConfirmOpen}
				title={
					editDestination
						? 'Alterar conta beneficiária'
						: 'Adicionar conta beneficiária'
				}
				confirmText={editDestination ? 'Alterar conta' : 'Criar conta'}
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					if (destinationToConfirm) {
						onCreateDestination(destinationToConfirm);
					}
				}}
				onCancel={() => setIsDestinationConfirmOpen(false)}
				onClose={() => setIsDestinationConfirmOpen(false)}
			>
				{editDestination ? (
					<TextL>
						Você tem certeza que deseja alterar essa conta
						beneficiária?
					</TextL>
				) : (
					<TextL>
						Você tem certeza que deseja criar essa conta
						beneficiária?
					</TextL>
				)}
			</ConfirmationModal>
			<ConfirmationModal
				isOpen={isDestinationRemoveOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					if (destinationToConfirm) {
						deleteDestination(destinationToConfirm.id);
					}
				}}
				onCancel={() => setIsDestinationRemoveOpen(false)}
				onClose={() => setIsDestinationRemoveOpen(false)}
			>
				<TextL>
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
		</ContainerFluid>
	);
};

export default AccountDestination;
