import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Button, Divider, Flex, Skeleton, Upload, notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

// Components
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { FeeDescriptions } from 'components/FeeDescriptions';
import { FileCheckLine } from 'components/Icons/FileCheckLine';
import { Heading1 } from 'components/Heading1';
import { Heading2 } from 'components/Heading2';
import { TextM, TextS } from 'components/Text';
import ActionsButtonsComponent from './ActionButtons/ActionsButtons';
import EmptyConfigComponent from './EmptyConfig/EmptyConfig';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import SignerMethodModal from './SignerMethodModal/SignerMethodModal';

// Hooks and services
import { ManagementService } from 'modules/management/services/management';
import { UploadService } from 'modules/core/services/UploadService';

// Types and helpers
import { AccountFee, AccountFeeMode, AccountFeeType } from 'types/Account';
import { ApiError } from 'types/ApiError';
import {
	ClicksignSignatureMethodType,
	IAccountFeeParsed,
	ICreateUpdateConfigProps,
	ITemplateConfig,
	ITemplateConfigAccountFee,
	ITemplateConfigCommission,
	ITemplateConfigCommissionPayload,
	ITemplateConfigEscrowAgreement,
	ITemplateConfigEscrowAgreementPayload,
	ITemplateConfigPaymentAgreement,
	ITemplateConfigSignature,
	ITemplateConfigSignaturePayload,
	ITemplateConfigTenantType,
	ITemplateConfigTenantTypePayload,
	TemplateType,
} from './types';

// Styles
import theme from 'theme/theme';
import { StyledMethods } from './TemplateConfig.styles';
import { ETenantType, translateTenant } from 'types/Company';
import { List } from 'types/List';
import { queryClient } from 'network/query';
import { PaymentType } from 'types/Posting';
import TenantTypeModal from './TenantType/TenantType';

export const signMethodOptions = ['E-mail', 'Selfie', 'Certificado digital'];
export const accountTypeOptions = ['Escrow', 'Prime'];

const TemplateConfigContainer = () => {
	/* Hooks */
	const [api, contextHolder] = notification.useNotification();
	const { id } = useParams();

	/* States */
	const [accountType, setAccountType] = useState<ETenantType>();
	const [selectedMethod, setSelectedMethod] = useState<TemplateType>();
	const [signMethods, setSignMethods] = useState<string[]>(
		signMethodOptions.filter(item => item !== 'Certificado digital'),
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
	const [isOpenSignerMethod, setIsOpenSignerMethod] =
		useState<boolean>(false);
	const [isOpenChargeConfigModal, setIsOpenChargeConfigModal] =
		useState<boolean>(false);
	const [isOpenAccountTypeModal, setIsOpenAccountTypeModal] =
		useState<boolean>(false);

	/* Vars */
	const companyId: string | undefined = id;
	const clicksignSignatureMethodDisplayName: Record<
		ClicksignSignatureMethodType,
		string
	> = {
		[ClicksignSignatureMethodType.EMAIL_TOKEN]: 'E-mail',
		[ClicksignSignatureMethodType.SELFIE]: 'Selfie',
		[ClicksignSignatureMethodType.ICP_CERTIFICATE]: 'Certificado',
	};

	/* Api calls */
	const { data, isLoading: isLoadingData } = useQuery<
		List<ITemplateConfig>,
		Error
	>({
		queryKey: ['templateConfig', companyId],
		queryFn: () =>
			ManagementService.getTemplateConfig({
				person_id: companyId,
			}),
		refetchOnWindowFocus: false,
	} as UseQueryOptions<List<ITemplateConfig>, Error>);

	const { mutate: createConfig, isPending: isPendingCreate } = useMutation<
		void,
		ApiError,
		{
			payload:
				| ITemplateConfigEscrowAgreementPayload
				| ITemplateConfigPaymentAgreement
				| ITemplateConfigCommissionPayload
				| ITemplateConfigSignaturePayload
				| ITemplateConfigTenantTypePayload;
		}
	>({
		mutationFn: ({ payload }) => {
			return ManagementService.createTemplateConfig(payload);
		},
		onSuccess: () => {
			closeAndClearHandler();

			api.success({
				message: 'Configuração criada com sucesso!',
			});
			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar criar a configuração',
			});
		},
	});

	const { mutate: deleteConfig, isPending: isPendingDelete } = useMutation<
		void,
		ApiError,
		{
			configId: string;
		}
	>({
		mutationFn: ({ configId }) => {
			return ManagementService.deleteTemplateConfig(configId);
		},
		onSuccess: () => {
			closeAndClearHandler();

			api.success({
				message: 'Configuração removida com sucesso!',
			});

			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar deletar a configuração',
			});
		},
	});

	const { mutate: updateConfig, isPending: isPendingUpdate } = useMutation<
		void,
		ApiError,
		{
			configId: string;
			payload:
				| ITemplateConfigEscrowAgreementPayload
				| ITemplateConfigPaymentAgreement
				| ITemplateConfigCommissionPayload
				| ITemplateConfigSignaturePayload
				| ITemplateConfigTenantTypePayload;
		}
	>({
		mutationFn: ({ configId, payload }) => {
			return ManagementService.updateTemplateConfig(configId, payload);
		},
		onSuccess: () => {
			closeAndClearHandler();

			api.success({
				message: 'Configuração editada com sucesso',
			});

			queryClient.invalidateQueries({
				queryKey: ['templateConfig', companyId],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um erro ao tentar editar a configuração',
			});
		},
	});

	const isLoading =
		isPendingUpdate || isPendingDelete || isLoadingData || isPendingCreate;
	const isLoadingFile = false;
	const isLoadingPaymentFile = false;

	// Charges
	const filteredCharges: ITemplateConfigCommission[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.FEE_AND_COMISSION
				? (item as unknown as ITemplateConfigCommission)
				: ({} as ITemplateConfigCommission),
		);
	const chargeDataPerson = filteredCharges?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const chargeDataGlobal = filteredCharges?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const chargeData: ITemplateConfigCommission | undefined = companyId
		? chargeDataPerson
		: chargeDataGlobal;

	// Signatures
	const filteredSignatures: ITemplateConfigSignature[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.SIGNATURE_METHOD
				? (item as unknown as ITemplateConfigSignature)
				: ({} as ITemplateConfigSignature),
		);

	const signatureDataPerson = filteredSignatures?.filter(
		item => item.configuration_payload && item.person_id,
	)[0];
	const signatureDataGlobal = filteredSignatures?.filter(
		item => item.configuration_payload && !item.person_id,
	)[0];
	const signatureData: ITemplateConfigSignature | undefined = companyId
		? signatureDataPerson
		: signatureDataGlobal;
	const methods =
		signatureData &&
		(signatureData?.configuration_payload
			?.methods as ClicksignSignatureMethodType[]);

	const getMethodDisplayName = (method: ClicksignSignatureMethodType) => {
		return (
			<Flex align="center">
				<TextS style={{ margin: '0 0.5rem' }}>
					{clicksignSignatureMethodDisplayName[method] ||
						'Método desconhecido'}
				</TextS>
				<Divider
					type="vertical"
					style={{ backgroundColor: theme.text }}
				/>
			</Flex>
		);
	};

	// Escrow Contract
	const props = {
		beforeUpload: (file: File) => {
			const isDocx =
				file.type ===
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

			if (!isDocx) {
				return api.error({
					description: 'Selecione um documento do tipo docx',
					message: 'Formato inválido',
				});
			}

			createConfigHandler({
				method: TemplateType.ESCROW_ACCOUNT_AGREEMENT,
				file: file,
			});
			return false;
		},
		showUploadList: false,
	};
	const filteredEscrowContract: ITemplateConfigEscrowAgreement[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.ESCROW_ACCOUNT_AGREEMENT
				? (item as unknown as ITemplateConfigEscrowAgreement)
				: ({} as ITemplateConfigEscrowAgreement),
		);

	const escrowContractPerson = filteredEscrowContract?.filter(
		item => item.id && item.person_id,
	)[0];
	const escrowContractGlobal = filteredEscrowContract?.filter(
		item => item.id && !item.person_id,
	)[0];
	const escrowContractData: ITemplateConfigEscrowAgreement | undefined =
		companyId ? escrowContractPerson : escrowContractGlobal;

	// Payment Contract
	const propsPayment = {
		beforeUpload: (file: File) => {
			const isDocx =
				file.type ===
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

			if (!isDocx) {
				return api.error({
					description: 'Selecione um documento do tipo docx',
					message: 'Formato inválido',
				});
			}

			createConfigHandler({
				method: TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
				file: file,
			});
			return false;
		},
		showUploadList: false,
	};
	const filteredPaymentContract:
		| ITemplateConfigPaymentAgreement[]
		| undefined = data?.content.map(item =>
		item.template_type === TemplateType.PAYMENT_ACCOUNT_AGREEMENT
			? (item as unknown as ITemplateConfigPaymentAgreement)
			: ({} as ITemplateConfigPaymentAgreement),
	);

	const paymentContractPerson = filteredPaymentContract?.filter(
		item => item.id && item.person_id,
	)[0];
	const paymentContractGlobal = filteredPaymentContract?.filter(
		item => item.id && !item.person_id,
	)[0];
	const paymentContractData: ITemplateConfigPaymentAgreement | undefined =
		companyId ? paymentContractPerson : paymentContractGlobal;

	// Tenant
	const filteredTenant: ITemplateConfigTenantType[] | undefined =
		data?.content.map(item =>
			item.template_type === TemplateType.TENANT_TYPE
				? (item as unknown as ITemplateConfigTenantType)
				: ({} as ITemplateConfigTenantType),
		);
	const tenantPerson = filteredTenant?.filter(
		item => item.id && item.person_id,
	)[0];
	const tenantGlobal = filteredTenant?.filter(
		item => item.id && !item.person_id,
	)[0];

	const tenantData = companyId ? tenantPerson : tenantGlobal;
	const tenantDataTranslated = companyId
		? translateTenant[
				tenantPerson?.configuration_payload.tenant as ETenantType
			]
		: translateTenant[
				tenantGlobal?.configuration_payload.tenant as ETenantType
			];

	const closeAndClearHandler = () => {
		setSelectedMethod(undefined);
		setIsOpenDeleteModal(false);
		setIsOpenSignerMethod(false);
		setIsOpenChargeConfigModal(false);
		setIsEditing(false);
		setAccountType(undefined);
		setIsOpenAccountTypeModal(false);
	};

	const createConfigHandler = useCallback(
		async (props: ICreateUpdateConfigProps) => {
			if (!selectedMethod && !props.method) {
				return api.error({
					description:
						'Por favor atualize a página e tente novamente',
					message: 'Houve um erro',
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD ||
				props.method === TemplateType.SIGNATURE_METHOD
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				createConfig({
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMISSION ||
				props.method === TemplateType.FEE_AND_COMISSION
			) {
				if (!props.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch.toString(),
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount || 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees.deposit || 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.pixDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.FIXED,
						amount: parsedFees?.tedDebit || 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement || 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},
				};

				createConfig({
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMISSION,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}

			if (
				props.method === TemplateType.ESCROW_ACCOUNT_AGREEMENT ||
				selectedMethod === TemplateType.ESCROW_ACCOUNT_AGREEMENT
			) {
				if (!props.file) {
					return api.error({
						description:
							'Não localizamos o seu arquivo, faça o upload novamente',
						message: 'Houve um erro',
					});
				}

				UploadService.uploadFile(props.file)
					.then(async upload => {
						createConfig({
							payload: {
								fileKey: upload.key,
								templateType:
									TemplateType.ESCROW_ACCOUNT_AGREEMENT,
								personId: companyId || undefined,
							},
						});
					})
					.catch(async (e: ApiError) => {
						api.error({
							description: e?.data?.message,
							message: 'Ocorreu um problema ao enviar o arquivo.',
						});
					});
			}

			if (
				props.method === TemplateType.PAYMENT_ACCOUNT_AGREEMENT ||
				selectedMethod === TemplateType.PAYMENT_ACCOUNT_AGREEMENT
			) {
				if (!props.file) {
					return api.error({
						description:
							'Não localizamos o seu arquivo, faça o upload novamente',
						message: 'Houve um erro',
					});
				}

				UploadService.uploadFile(props.file)
					.then(async upload => {
						createConfig({
							payload: {
								fileKey: upload.key,
								templateType:
									TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
								personId: companyId || undefined,
							},
						});
					})
					.catch(async (e: ApiError) => {
						api.error({
							description: e?.data?.message,
							message: 'Ocorreu um problema ao enviar o arquivo.',
						});
					});
			}

			if (
				props.method === TemplateType.TENANT_TYPE ||
				selectedMethod === TemplateType.TENANT_TYPE
			) {
				if (!props.tenantType) {
					return api.error({
						description: 'Escolha um tipo de conta',
						message: 'Houve um erro',
					});
				}

				const payload: ITemplateConfigTenantTypePayload = {
					templateType: TemplateType.TENANT_TYPE,
					personId: companyId || undefined,
					configurationPayload: {
						tenant: props.tenantType || ETenantType.DEFAULT,
					},
				};

				createConfig({
					payload: payload,
				});
			}
		},
		[companyId, selectedMethod, signatureData, signMethods],
	);

	const editConfigHandler = useCallback(
		(props: ICreateUpdateConfigProps) => {
			if (!selectedMethod && !props?.method) {
				return api.error({
					description:
						'Por favor atualize a página e tente novamente',
					message: 'Houve um erro',
				});
			}

			if (
				selectedMethod === TemplateType.SIGNATURE_METHOD ||
				props.method === TemplateType.SIGNATURE_METHOD
			) {
				const parseMethod = [];

				if (!signMethods.length) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				if (signMethods.includes('E-mail')) {
					parseMethod.push(ClicksignSignatureMethodType.EMAIL_TOKEN);
				}
				if (signMethods.includes('Selfie')) {
					parseMethod.push(ClicksignSignatureMethodType.SELFIE);
				}
				if (signMethods.includes('Certificado digital')) {
					parseMethod.push(
						ClicksignSignatureMethodType.ICP_CERTIFICATE,
					);
				}

				updateConfig({
					configId: signatureData?.id || '',
					payload: {
						configurationPayload: {
							methods: parseMethod,
						},
						templateType: TemplateType.SIGNATURE_METHOD,
						personId: companyId || undefined,
					},
				});
			}

			if (
				selectedMethod === TemplateType.FEE_AND_COMISSION ||
				props.method === TemplateType.FEE_AND_COMISSION
			) {
				if (!props?.fees) {
					return api.error({
						description: 'Preencha corretamente as tarifas',
						message: 'Houve um erro',
					});
				}

				const fees: AccountFee = props.fees as AccountFee;
				const parsedFees: IAccountFeeParsed =
					props.fees as IAccountFeeParsed;
				const withDestinationPayload: ITemplateConfigAccountFee = {
					beneficiary: props.destination?.taxpayer_id
						? {
								taxpayer_id: props.destination.taxpayer_id,
								name: props.destination.name,
								bank: props.destination.bank,
								branch: props.destination.branch,
								account: props.destination.account,
								type: props.destination.type,
							}
						: undefined,
					mode: (parsedFees.feeMode as AccountFeeMode) || fees.mode,
					default_payment_type: PaymentType.PIX,
					// TARIFA DA CONTA:
					maintenance: {
						day: parsedFees.maintenanceDay,
						amount: parsedFees?.maintenanceAmount
							? parseFloat(
									parsedFees?.maintenanceAmount?.toString(),
								)
							: 0,
						commission_percent_amount:
							parsedFees?.commissionMaintenanceAmount,
					},
					// PIX ENTRADA:
					deposit: {
						amount_type:
							(parsedFees?.depositType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.deposit
							? parseFloat(parsedFees.deposit.toString())
							: 0,
					},
					// PIX SAIDA:
					withdrawal: {
						amount_type:
							(parsedFees?.pixDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.pixDebit
							? parseFloat(parsedFees?.pixDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionWithdrawalAmount,
					},
					// TED SAIDA:
					ted_transfer: {
						amount_type:
							(parsedFees?.tedDebitType as AccountFeeType) ||
							AccountFeeType.NONE,
						amount: parsedFees?.tedDebit
							? parseFloat(parsedFees?.tedDebit.toString())
							: 0,
						commission_percent_amount:
							parsedFees?.commissionTedTransferAmount,
					},
					// TARIFA DE BOLETO:
					charge_settlement: {
						amount_type: AccountFeeType.FIXED,
						amount: parsedFees?.chargeSettlement
							? parseFloat(
									parsedFees?.chargeSettlement.toString(),
								)
							: 0,
						commission_percent_amount:
							parsedFees?.commissionChargeSettlementAmount,
					},
				};

				updateConfig({
					configId: chargeData?.id || '',
					payload: {
						configurationPayload: props.destination
							? withDestinationPayload
							: props.fees,
						templateType: TemplateType.FEE_AND_COMISSION,
						personId: companyId || undefined,
					} as ITemplateConfigCommissionPayload,
				});
			}

			if (
				selectedMethod === TemplateType.TENANT_TYPE ||
				props.method === TemplateType.TENANT_TYPE
			) {
				if (!accountType) {
					return api.error({
						description: 'Selecione ao menos uma opção',
						message: 'Campos vazios',
					});
				}

				const payload: ITemplateConfigTenantTypePayload = {
					configurationPayload: {
						tenant: accountType,
					},
					templateType: TemplateType.TENANT_TYPE,
					personId: companyId || undefined,
				};

				updateConfig({
					configId: tenantData?.id || '',
					payload: payload,
				});
			}
		},
		[
			signMethods,
			selectedMethod,
			signatureData,
			chargeData,
			accountType,
			tenantData,
			companyId,
		],
	);

	const deleteConfigHandler = useCallback(() => {
		if (
			selectedMethod === TemplateType.SIGNATURE_METHOD &&
			signatureData?.id
		) {
			return deleteConfig({
				configId: signatureData?.id || '',
			});
		}

		if (
			selectedMethod === TemplateType.FEE_AND_COMISSION &&
			chargeData?.id
		) {
			return deleteConfig({
				configId: chargeData?.id || '',
			});
		}

		if (selectedMethod === TemplateType.ESCROW_ACCOUNT_AGREEMENT) {
			return deleteConfig({
				configId: escrowContractData?.id || '',
			});
		}

		if (selectedMethod === TemplateType.PAYMENT_ACCOUNT_AGREEMENT) {
			return deleteConfig({
				configId: paymentContractData?.id || '',
			});
		}

		if (selectedMethod === TemplateType.TENANT_TYPE) {
			return deleteConfig({
				configId: tenantData?.id || '',
			});
		}
	}, [
		signatureData,
		selectedMethod,
		tenantData,
		paymentContractData,
		escrowContractData,
		chargeData,
	]);

	const handleSetSignMethods = (methods: string[]) => {
		if (methods.length > 1 && methods.includes('Certificado digital')) {
			api.error({
				description:
					'Certificado digital não pode ser selecionado com outros métodos',
				message: 'Erro',
			});
			return;
		}

		setSignMethods(methods);
	};

	const downloadDocumentHandler = (fileUrl: string) => {
		if (!fileUrl) {
			return api.error({
				description:
					'O arquivo não está disponível para download no momento, tente novamente mais tarde.',
				message: 'Arquivo não disponível',
			});
		}

		saveAs(fileUrl);
	};

	return (
		<>
			{contextHolder}
			<Heading1>Configurações de abertura de contas</Heading1>
			<TextM color={theme.textGray} style={{ marginTop: '-14px' }}>
				As configurações alteradas serão refletidas nas próximas
				aberturas de contas.
			</TextM>
			<Divider />

			{/* Tarifas */}
			{!!chargeData?.configuration_payload ? (
				<div>
					<FeeDescriptions
						title="Tarifas e rebates"
						fees={chargeData.configuration_payload}
						handleOnEdit={() => {}}
						onEditComponent={
							<ActionsButtonsComponent
								editTitleButton="Editar tarifas"
								onClickEdit={() => {
									setSelectedMethod(
										TemplateType.FEE_AND_COMISSION,
									);
									setIsEditing(true);
									setIsOpenChargeConfigModal(true);
								}}
								onClickRemove={() => {
									setSelectedMethod(
										TemplateType.FEE_AND_COMISSION,
									);
									setIsOpenDeleteModal(true);
								}}
							/>
						}
					/>
				</div>
			) : (
				<EmptyConfigComponent
					subtitle="Esta conta não possui nenhuma configuração de tarifas para abertura de novas contas."
					title="Tarifas e rebates"
					button={
						<Button
							type="primary"
							onClick={() => setIsOpenChargeConfigModal(true)}
							style={{ width: '13rem' }}
						>
							<TextS color={theme.white}>
								Configurar tarifas
							</TextS>
							<AddCircleIcon color={theme.white} size={18} />
						</Button>
					}
				/>
			)}

			{/* Contrato Escrow */}
			<Divider />
			{!!escrowContractData ? (
				<div>
					<Flex align="center" justify="space-between">
						<Heading2>Template de contrato Conta Escrow</Heading2>

						<ActionsButtonsComponent
							editTitleButton="Enviar outro arquivo"
							onClickRemove={() => {
								setSelectedMethod(
									TemplateType.ESCROW_ACCOUNT_AGREEMENT,
								);
								setIsOpenDeleteModal(true);
							}}
						/>
					</Flex>
					{isLoadingFile ? (
						<div
							style={{ marginTop: '1rem', marginBottom: '-1rem' }}
						>
							<Skeleton
								active
								paragraph={{
									rows: 0,
									width: 160,
								}}
							/>
						</div>
					) : (
						<Button
							type="text"
							style={{ marginTop: '2rem', padding: '1rem 0' }}
							onClick={() =>
								downloadDocumentHandler(
									escrowContractData?.file_url,
								)
							}
						>
							<Flex align="center" justify="flex-start">
								<FileCheckLine
									color={theme.primary}
									size={18}
								/>
								<TextS
									color={theme.textGray}
									style={{ marginLeft: '0.5rem' }}
								>
									{escrowContractData?.file_name ||
										'Template Conta Escrow'}
								</TextS>
							</Flex>
						</Button>
					)}
				</div>
			) : (
				<EmptyConfigComponent
					subtitle="Esta conta não possui nenhum template de contrato para abertura de novas contas."
					title="Template de contrato Conta Escrow"
					button={
						<Upload {...props} accept=".docx">
							<Button type="primary" style={{ width: '13rem' }}>
								<TextS color={theme.white}>
									Adicionar template
								</TextS>
								<AddCircleIcon color={theme.white} size={18} />
							</Button>
						</Upload>
					}
				/>
			)}

			{/* Contrato Livre */}
			<Divider />
			{!!paymentContractData ? (
				<div>
					<Flex align="center" justify="space-between">
						<Heading2>Template de contrato Conta Livre</Heading2>

						<ActionsButtonsComponent
							editTitleButton="Enviar outro arquivo"
							onClickRemove={() => {
								setSelectedMethod(
									TemplateType.PAYMENT_ACCOUNT_AGREEMENT,
								);
								setIsOpenDeleteModal(true);
							}}
						/>
					</Flex>
					{isLoadingPaymentFile ? (
						<div
							style={{ marginTop: '1rem', marginBottom: '-1rem' }}
						>
							<Skeleton
								active
								paragraph={{
									rows: 0,
									width: 160,
								}}
							/>
						</div>
					) : (
						<Button
							type="text"
							style={{ marginTop: '2rem', padding: '1rem 0' }}
							onClick={() =>
								downloadDocumentHandler(
									paymentContractData?.file_url,
								)
							}
						>
							<Flex align="center" justify="flex-start">
								<FileCheckLine
									color={theme.primary}
									size={18}
								/>
								<TextS
									color={theme.textGray}
									style={{ marginLeft: '0.5rem' }}
								>
									{paymentContractData?.file_name ||
										'Template Conta Livre'}
								</TextS>
							</Flex>
						</Button>
					)}
				</div>
			) : (
				<EmptyConfigComponent
					subtitle="Esta conta não possui nenhum template de contrato para abertura de novas contas."
					title="Template de contrato Conta Livre"
					button={
						<Upload {...propsPayment} accept=".docx">
							<Button type="primary" style={{ width: '13rem' }}>
								<TextS color={theme.white}>
									Adicionar template
								</TextS>
								<AddCircleIcon color={theme.white} size={18} />
							</Button>
						</Upload>
					}
				/>
			)}

			{/* Assinatura */}
			<Divider />
			{!!signatureData ? (
				<>
					<Flex align="center" justify="space-between">
						<Heading2>Método de assinatura</Heading2>
						<ActionsButtonsComponent
							editTitleButton="Editar método"
							onClickEdit={() => {
								setSelectedMethod(
									TemplateType.SIGNATURE_METHOD,
								);
								setIsEditing(true);
								setIsOpenSignerMethod(true);
							}}
							onClickRemove={() => {
								setSelectedMethod(
									TemplateType.SIGNATURE_METHOD,
								);
								setIsOpenDeleteModal(true);
							}}
						/>
					</Flex>
					<Flex align="center" style={{ marginTop: '2rem' }}>
						<TextS color={theme.textGray}>
							Método de assinatura:
						</TextS>
						<StyledMethods>
							{methods?.map(getMethodDisplayName)}
						</StyledMethods>
					</Flex>
				</>
			) : (
				<EmptyConfigComponent
					subtitle="Esta conta não possui nenhum método de assinatura definido para abertura de novas contas."
					title="Método de assinatura"
					button={
						<Button
							type="primary"
							onClick={() => setIsOpenSignerMethod(true)}
							style={{ width: '13rem' }}
						>
							<TextS color={theme.white}>Adicionar método</TextS>
							<AddCircleIcon color={theme.white} size={18} />
						</Button>
					}
				/>
			)}

			{/* Tipo de tenant */}
			<Divider />
			{!!tenantData ? (
				<>
					<Flex align="center" justify="space-between">
						<Heading2>Tipo de tenant</Heading2>
						<ActionsButtonsComponent
							editTitleButton="Editar tenant"
							onClickEdit={() => {
								setSelectedMethod(TemplateType.TENANT_TYPE);
								setIsEditing(true);
								setIsOpenAccountTypeModal(true);
							}}
							onClickRemove={() => {
								setSelectedMethod(TemplateType.TENANT_TYPE);
								setIsOpenDeleteModal(true);
							}}
						/>
					</Flex>
					<Flex align="center" style={{ margin: '2rem 0' }}>
						<TextS color={theme.textGray}>Tipo de tenant:</TextS>
						<TextS style={{ margin: '0 0.5rem' }}>
							{tenantDataTranslated || '-'}
						</TextS>
					</Flex>
				</>
			) : (
				<div style={{ marginBottom: '2rem' }}>
					<EmptyConfigComponent
						subtitle="Esta conta não possui nenhuma configuração de tipo de tenant para abertura de novas contas."
						title="Tipo de tenant"
						button={
							<Button
								type="primary"
								onClick={() => setIsOpenAccountTypeModal(true)}
								style={{ width: '13rem' }}
							>
								<TextS color={theme.white}>
									Adicionar tenant
								</TextS>
								<AddCircleIcon color={theme.white} size={18} />
							</Button>
						}
					/>
				</div>
			)}

			{/* Modals */}
			<FeeSettingsModalComission
				accountId={companyId}
				isOpen={isOpenChargeConfigModal}
				onClose={() => setIsOpenChargeConfigModal(false)}
				feesSettings={{
					...chargeData?.configuration_payload,
					default_payment_type: PaymentType.PIX,
					mode:
						chargeData?.configuration_payload.mode ||
						AccountFeeMode.DIRECT_DEBIT,
					commission_account_destination: {
						account:
							chargeData?.configuration_payload.beneficiary
								?.account || '',
						bank:
							chargeData?.configuration_payload.beneficiary
								?.bank || '',
						branch:
							chargeData?.configuration_payload.beneficiary
								?.branch || '',
						name:
							chargeData?.configuration_payload.beneficiary
								?.name || '',
						taxpayer_id:
							chargeData?.configuration_payload.beneficiary
								?.taxpayer_id || '',
						type:
							chargeData?.configuration_payload.beneficiary
								?.type || '',
					},
				}}
				isEdit
				isTemplateConfig
				handleConfirm={(fees, destination) => {
					isEditing
						? editConfigHandler({
								method: TemplateType.FEE_AND_COMISSION,
								fees: fees,
								destination: destination,
							})
						: createConfigHandler({
								method: TemplateType.FEE_AND_COMISSION,
								fees: fees,
								destination: destination,
							});
				}}
			/>
			<SignerMethodModal
				isOpen={isOpenSignerMethod}
				onClose={() => setIsOpenSignerMethod(false)}
				options={
					signMethods || signatureData?.configuration_payload.methods
				}
				setOptions={options => handleSetSignMethods(options)}
				onSave={() =>
					isEditing
						? editConfigHandler({
								method: TemplateType.SIGNATURE_METHOD,
							})
						: createConfigHandler({
								method: TemplateType.SIGNATURE_METHOD,
							})
				}
			/>
			<TenantTypeModal
				isOpen={isOpenAccountTypeModal}
				onClose={() => {
					setAccountType(tenantData?.configuration_payload?.tenant);
					setIsOpenAccountTypeModal(false);
				}}
				tenantType={
					accountType || tenantData?.configuration_payload.tenant
				}
				setTenantType={setAccountType}
				onSave={type =>
					isEditing
						? editConfigHandler({
								method: TemplateType.TENANT_TYPE,
								tenantType: type,
							})
						: createConfigHandler({
								method: TemplateType.TENANT_TYPE,
								tenantType: type,
							})
				}
			/>
			<ConfirmationModal
				isOpen={isOpenDeleteModal}
				danger={true}
				isLoading={isLoading}
				title="Remover configuração"
				confirmText="Remover configuração"
				cancelText="Cancelar"
				onConfirm={deleteConfigHandler}
				onCancel={() => setIsOpenDeleteModal(false)}
				onClose={() => setIsOpenDeleteModal(false)}
			>
				<TextM>
					Você tem certeza que deseja remover essa configuração?
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default TemplateConfigContainer;
