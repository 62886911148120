export interface TransferRuleFilters {
	account_id?: string;
	page?: number;
	size?: number;
	order?: 'ASC' | 'DESC';
	sort?: string;
}

export enum SelectType {
	FIXED = 'FIXED',
	PERCENT = 'PERCENT',
}

export enum TransferFrequency {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	INTERVAL = 'INTERVAL',
}

export enum DailyTransferFrequency {
	ONCE = 'ONCE',
	MULTIPLE = 'MULTIPLE',
}

export enum TransferMethod {
	PIX = 'PIX',
	TED = 'TED',
}

export enum DaysOfWeek {
	SUNDAY = 'SUNDAY',
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
}

export interface AccountDestination {
	account_destination_id: string;
	split_percentage: number;
}

export type TransferRuleTime = {
	hour: number;
	minute: number;
};

export interface TransferRuleDto {
	id?: string;
	account_id: string;
	amount_type: SelectType;
	amount?: number;
	transfer_method: TransferMethod;
	min_value_in_account_after_transfer: number;
	frequency: TransferFrequency;
	days_of_week: string[];
	status?: TransferRuleStatus;
	transfer_window_start?: TransferRuleTime;
	transfer_window_end?: TransferRuleTime;
	transfer_interval?: number;
	fixed_transfer_time?: TransferRuleTime;
	destinations: AccountDestination[];
}

export enum TransferRuleStatus {
	EXECUTING = 'EXECUTING',
	PAUSED = 'PAUSED',
	ACTIVE = 'ACTIVE',
	ERROR_PAUSED = 'ERROR_PAUSED',
	DELETED = 'DELETED',
}

export interface TransferRuleCreated {
	transfer_rule: TransferRule;
	destinations: AccountDestination[];
}

export interface TransferRule {
	id: string;
	account_id: string;
	amount_type: SelectType;
	amount: number;
	transfer_method: TransferMethod;
	min_value_in_account_after_transfer: number;
	frequency: TransferFrequency;
	days_of_week: string[];
	transfer_window_start?: string;
	transfer_window_end?: string;
	transfer_interval?: number;
	fixed_transfer_time?: string;
	destinations: AccountDestination[];
	status: TransferRuleStatus;
}

export interface IManualComplianceApprovePayload {
	accountId: string;
}
