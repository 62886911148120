import { useMemo } from 'react';
import { EscrowAccounts } from './pages/EscrowAccounts';
import { Tabs } from 'components/Tabs';
import { PageWrapper } from 'components/PageWrapper';
import { TabElement } from 'components/Tabs/Tabs';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { ClientsList } from './pages/ClientsList';
import TemplateConfigContainer from './pages/TemplateConfig/TemplateConfig';
import { HiOutlineGlobeAlt } from 'react-icons/hi2';
import { RiBriefcaseLine, RiCurrencyLine } from 'react-icons/ri';

const ManagementPage = () => {
	const tabElements = useMemo<TabElement[]>(() => {
		const tabs: TabElement[] = [
			{
				title: 'Contas',
				content: <EscrowAccounts />,
				path: 'accounts',
				icon: (
					<RiCurrencyLine
						size={20}
						style={{ marginBottom: '3px', marginRight: '-6px' }}
					/>
				),
			},
			{
				title: 'Clientes',
				content: <ClientsList />,
				path: 'company',
				icon: (
					<RiBriefcaseLine
						size={20}
						style={{ marginBottom: '3px', marginRight: '-6px' }}
					/>
				),
			},
			{
				title: 'Configurações Globais',
				icon: (
					<HiOutlineGlobeAlt
						size={20}
						style={{ marginBottom: '3px', marginRight: '-6px' }}
					/>
				),
				content: <TemplateConfigContainer />,
				path: 'config',
			},
		];

		return tabs;
	}, []);

	return (
		<>
			<PageHeaderWithButton isFluid title="Gestão" />
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
		</>
	);
};

export default ManagementPage;
