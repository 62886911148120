import { useLocation } from 'react-router-dom';
import { EscrowIcon, contaLivre } from '../constants/routes';
import { FinanceIcon } from 'components/Icons/Finance';
import theme from 'theme/theme';
import { AccountType } from 'types/Account';

export const useIsEscrowAccountRoute = (): boolean => {
	const location = useLocation();
	const currentPath = location.pathname;

	return currentPath.includes('escrow');
};

export const getAccountRouteType = (): AccountType => {
	const location = useLocation();
	const currentPath = location.pathname;

	if (currentPath.includes(contaLivre)) {
		return AccountType.PAYMENT;
	}

	return AccountType.ESCROW;
};

export const getAccountRouteTypeName = (): string => {
	const accountType = getAccountRouteType();

	if (accountType === AccountType.PAYMENT) {
		return 'Conta Livre';
	}

	return 'Conta Escrow';
};

export const getAccountRouteTypeIcon = () => {
	const accountType = getAccountRouteType();

	if (accountType === AccountType.PAYMENT) {
		return <FinanceIcon color={theme.textGray} size={24} />;
	}

	return <EscrowIcon />;
};
