import { NetworkApi } from 'network';
import {
	AutoCompleteInfoParams,
	AutoCompletePFInfo,
	AutoCompletePJInfo,
	Company,
	CompanyDto,
	CompanyFilters,
	CompanyFund,
} from 'types/Company';
import { List } from 'types/List';
import { Representative } from 'types/Representative';

export class PersonsService extends NetworkApi {
	public static getPersons(filters?: CompanyFilters) {
		return this.get<List<Company>, CompanyFilters>('/persons', filters);
	}

	public static createCompany(company: CompanyDto) {
		return this.post<{ id: string }, CompanyDto>('/persons', company);
	}

	public static async getCompany(
		person_id: string,
		filters?: CompanyFilters,
	) {
		const company = await this.get<Company>(
			`/persons/${person_id}`,
			filters,
		);
		return {
			...company,
			id: person_id,
		};
	}

	public static async patchCompany(
		person_id: string,
		company: Partial<CompanyDto>,
	) {
		return this.patch<void, Partial<CompanyDto>>(
			`/persons/${person_id}`,
			company,
		);
	}

	public static async getCompaniesByIds(
		companyIds: string[],
	): Promise<Company[]> {
		const companies = await Promise.all(
			companyIds.map(id => {
				return this.getCompany(id);
			}),
		);
		return companies;
	}

	public static async getCompanyFund(
		companyFundId: string,
	): Promise<CompanyFund> {
		const company = await this.getCompany(companyFundId);
		if (
			company &&
			company.fund_manager_id &&
			company.fund_securitizer_id &&
			company.fund_administrator_id
		) {
			const fundCompanies: CompanyFund = await Promise.all([
				this.getCompany(companyFundId),
				this.getCompany(company.fund_manager_id),
				this.getCompany(company.fund_securitizer_id),
				this.getCompany(company.fund_administrator_id),
			]).then(companies => ({
				fund: companies[0],
				manager: companies[1],
				securitizer: companies[2],
				administrator: companies[3],
			}));
			return fundCompanies;
		}
		return {
			fund: null,
			manager: null,
			securitizer: null,
			administrator: null,
		};
	}

	public static async updateRepresentative(
		person_id: string,
		representative: Representative,
	) {
		const result = await this.patch<void, Representative>(
			`/persons/${person_id}/representatives/${representative.id}`,
			representative,
		);

		return result;
	}

	public static async createRepresentative(
		person_id: string,
		representative: Representative,
	) {
		const result = await this.post<{ id: string }, Representative>(
			`/persons/${person_id}/representatives`,
			representative,
		);

		return result;
	}

	public static async deleteRepresentative(
		person_id: string,
		representativeId: string,
	) {
		const result = await this.delete<void>(
			`/persons/${person_id}/representatives/${representativeId}`,
		);

		return result;
	}

	public static async getAutoComplete(
		params: AutoCompleteInfoParams,
	): Promise<AutoCompletePFInfo | AutoCompletePJInfo> {
		const autoCompleteData = await this.get<
			AutoCompletePFInfo | AutoCompletePJInfo
		>(`/auto-complete`, params);

		return autoCompleteData;
	}
}
