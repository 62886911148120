import { Button, Col, Flex, Row, notification } from 'antd';
import {
	Account,
	AccountStatus,
	AccountType,
	AccountUpdateDto,
} from 'types/Account';
import { Documents } from 'components/Documents';
import { AccountDocumentsList } from 'constants/account';
import { AccountDestination } from 'components/AccountDestination';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AccountsService } from 'modules/escrow/services';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { queryClient } from 'network/query';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import { FeeDescriptions } from 'components/FeeDescriptions';
import { TransferRuleList } from 'modules/management/pages/TransferRulePage/TransferRuleList';
import { ManagementService } from 'modules/management/services/management';
import { Heading2 } from 'components/Heading2';
import { CreateTransferRuleModal } from 'modules/management/pages/TransferRulePage/components/CreateTransferRuleModal';
import ActionsButtonsComponent from 'modules/management/pages/TemplateConfig/ActionButtons/ActionsButtons';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { ETenantType, translateTenant } from 'types/Company';
import { BordeWrapper } from '../styles';
import AccountTypeModal from '../components/AccountType/AccountType';
import TenantTypeModal from 'modules/management/pages/TemplateConfig/TenantType/TenantType';

interface IEscrowDetailsConfigurationProps {
	account: Account;
}
const EscrowDetailsConfiguration: React.FC<
	IEscrowDetailsConfigurationProps
> = ({ account }) => {
	const { type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();

	const [accountType, setAccountType] = useState<AccountType>(
		account.account_type,
	);
	const [tenantType, setTenantType] = useState<ETenantType>();
	const [isOpenTenantTypeModal, setIsOpenTenantTypeModal] =
		useState<boolean>(false);
	const [isOpenAccountTypeModal, setIsOpenAccountTypeModal] =
		useState<boolean>(false);
	const [isFeeSettingsModalOpen, setIsFeeSettingsModalOpen] = useState(false);
	const [isOpenTransferRuleModal, setIsOpenTransferRuleModal] =
		useState(false);
	const [isUpdateAccountInfoModalOpen, setIsUpdateAccountInfoModalOpen] =
		useState(false);

	const { mutate, isPending } = useMutation<void, ApiError, AccountUpdateDto>(
		{
			mutationFn: payload => {
				return AccountsService.updateAccount(account.id, payload);
			},
			onSuccess: () => {
				setIsFeeSettingsModalOpen(false);
				setIsOpenAccountTypeModal(false);
				setIsOpenTenantTypeModal(false);
				setIsUpdateAccountInfoModalOpen(true);
				queryClient.refetchQueries({
					queryKey: ['accountDetails', account.id],
				});
			},
			onError: e => {
				setIsFeeSettingsModalOpen(false);
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		},
	);

	const { data: transferRulesListData, isLoading: transferRulesListLoading } =
		useQuery({
			queryKey: ['transferRulesList'],
			enabled: !!account,
			queryFn: () =>
				ManagementService.getTransferRules({
					account_id: account?.id,
				}),
		});

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<FeeDescriptions
						title="Tarifas"
						fees={account.fees}
						handleOnEdit={
							type === AccessType.FINANCIAL_INSTITUTION
								? () => setIsFeeSettingsModalOpen(true)
								: undefined
						}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						title="Contratos"
						documentsObject={account.documents}
						documentsList={AccountDocumentsList}
						onChangeDocumentsObject={document => {
							mutate({
								documents: {
									agreement: document.opening_agreement,
								},
							});
						}}
						isDisabled={
							!(
								type === AccessType.FINANCIAL_INSTITUTION &&
								account.status ===
									AccountStatus.AWAITING_BACKOFFICE_ANALYSIS
							)
						}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<AccountDestination
						account={account}
						destinations={account.destinations}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Flex
						align="center"
						justify="space-between"
						style={{ marginTop: '2rem' }}
					>
						<Heading2>Tipo de tenant</Heading2>
						{type === AccessType.FINANCIAL_INSTITUTION &&
							account.status ===
								AccountStatus.AWAITING_BACKOFFICE_ANALYSIS && (
								<ActionsButtonsComponent
									editTitleButton="Editar tenant"
									onClickEdit={() =>
										setIsOpenTenantTypeModal(true)
									}
								/>
							)}
					</Flex>
					<BordeWrapper>
						<TextS color={theme.textGray}>Tipo de tenant:</TextS>
						<TextS style={{ margin: '0 0.5rem' }}>
							{translateTenant[
								account.tenant_type as ETenantType
							] || '-'}
						</TextS>
					</BordeWrapper>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Flex
						align="center"
						justify="space-between"
						style={{ marginTop: '2rem' }}
					>
						<Heading2>Tipo de conta</Heading2>
						{type === AccessType.FINANCIAL_INSTITUTION &&
							account.status ===
								AccountStatus.AWAITING_BACKOFFICE_ANALYSIS && (
								<ActionsButtonsComponent
									editTitleButton="Editar tipo de conta"
									onClickEdit={() =>
										setIsOpenAccountTypeModal(true)
									}
								/>
							)}
					</Flex>
					<BordeWrapper>
						<TextS color={theme.textGray}>Tipo de conta:</TextS>
						<TextS style={{ margin: '0 0.5rem' }}>
							{account.account_type === AccountType.ESCROW
								? 'Escrow'
								: 'Livre'}
						</TextS>
					</BordeWrapper>
				</Col>
			</Row>
			<Flex
				justify="space-between"
				align="center"
				style={{ marginTop: '2rem' }}
			>
				<Heading2>Regras de transferências</Heading2>
				{type === AccessType.FINANCIAL_INSTITUTION && (
					<Button
						type="primary"
						onClick={() => setIsOpenTransferRuleModal(true)}
					>
						Adicionar regra de transferência
					</Button>
				)}
			</Flex>
			<TransferRuleList
				account={account}
				transferRules={transferRulesListData}
				onlyView={type !== AccessType.FINANCIAL_INSTITUTION}
			/>

			{/* Modals */}
			<CreateTransferRuleModal
				accountData={account}
				isOpen={isOpenTransferRuleModal}
				onClose={() => {
					setIsOpenTransferRuleModal(false);
				}}
			/>
			<FeeSettingsModalComission
				accountId={account.id}
				isOpen={isFeeSettingsModalOpen}
				onClose={() => setIsFeeSettingsModalOpen(false)}
				feesSettings={account.fees}
				loading={isPending}
				handleConfirm={values => {
					mutate({
						fees: values,
					});
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUpdateAccountInfoModalOpen}
				message="A conta foi editada com sucesso!"
				title="Conta editada"
				icon={<CheckmarkIcon />}
				onClose={() => setIsUpdateAccountInfoModalOpen(false)}
			/>
			<TenantTypeModal
				isOpen={isOpenTenantTypeModal}
				tenantType={tenantType || account.tenant_type}
				setTenantType={setTenantType}
				onSave={type =>
					mutate({
						tenantType: type,
					})
				}
				onClose={() => {
					setTenantType(account.tenant_type);
					setIsOpenTenantTypeModal(false);
				}}
			/>
			<AccountTypeModal
				isOpen={isOpenAccountTypeModal}
				accountType={accountType}
				setAccountType={setAccountType}
				onSave={type =>
					mutate({
						accountType: type,
					})
				}
				onClose={() => {
					setIsOpenAccountTypeModal(false);
				}}
			/>
		</>
	);
};

export default EscrowDetailsConfiguration;
