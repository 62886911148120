import styled from 'styled-components';

export const StyledMethods = styled.div`
	display: flex;

	:last-child {
		.ant-divider {
			display: none;
		}
	}
`;
