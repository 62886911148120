import { Flex } from 'antd';
import { ReactNode } from 'react';

// Components
import { Heading2 } from 'components/Heading2';
import { TextS } from 'components/Text';

// Styles
import theme from 'theme/theme';

interface IEmptyConfigComponentProps {
	title: string;
	subtitle: string;
	button: ReactNode;
}

const EmptyConfigComponent = ({
	title,
	subtitle,
	button,
}: IEmptyConfigComponentProps) => {
	return (
		<>
			<Flex justify="space-between" align="flex-start">
				<div>
					<Heading2>{title}</Heading2>
					<TextS color={theme.textGray} style={{ marginTop: '8px' }}>
						{subtitle}
					</TextS>
				</div>
				{button}
			</Flex>
		</>
	);
};

export default EmptyConfigComponent;
