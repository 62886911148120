import { Button, Flex } from 'antd';
import { EditIcon } from 'components/Icons/Edit';
import { TrashIcon } from 'components/Icons/Trash';
import { TextS } from 'components/Text';
import theme from 'theme/theme';

interface IActionsButtonsComponentProps {
	onClickRemove?: () => void;
	onClickEdit?: () => void;
	editTitleButton?: string;
}

const ActionsButtonsComponent = ({
	onClickRemove,
	onClickEdit,
	editTitleButton,
}: IActionsButtonsComponentProps) => {
	return (
		<Flex align="center">
			{!!onClickRemove && (
				<Button onClick={onClickRemove} style={{ width: '13rem' }}>
					<TextS color={theme.danger}>Remover configuração</TextS>
					<TrashIcon color={theme.danger} size={15} />
				</Button>
			)}
			{!!onClickEdit && (
				<Button
					type="primary"
					style={{ marginLeft: '1rem', width: '13rem' }}
					onClick={onClickEdit}
				>
					<TextS color={theme.white}>{editTitleButton}</TextS>
					<EditIcon color={theme.white} size={15} />
				</Button>
			)}
		</Flex>
	);
};

export default ActionsButtonsComponent;
