import theme from 'theme/theme';
import { AccountPermission, AccountStatus } from 'types/Account';
import { BusinessType } from 'types/Company';

export const AccountPermissionRestricted = [] as AccountPermission[];

export const AccountPermissionDescription = {
	[AccountPermission.CREATE_ACCOUNT]: 'Criar conta',
	[AccountPermission.CREATE_BENEFICIARY]: 'Criar beneficiário',
	[AccountPermission.VIEW_ACCOUNT]: 'Visualizar contas',
	[AccountPermission.POSTING_REVIEW]: 'Aprovar/reprovar lançamentos',
	[AccountPermission.POSTING_CREATE]: 'Realizar lançamentos',
	[AccountPermission.POSTING_VIEW]: 'Visualizar lançamentos',
	[AccountPermission.VIEW_WALLET]: 'Visualizar carteiras',
	[AccountPermission.CREATE_WALLET]: 'Criar carteiras',
	[AccountPermission.UPDATE_WALLET]: 'Alterar carteiras',
	[AccountPermission.ARCHIVE_WALLET]: 'Arquivar carteiras',
	[AccountPermission.CREATE_CHARGE]: 'Criar cobranças',
	[AccountPermission.CANCEL_CHARGE]: 'Cancelar cobranças',
	[AccountPermission.VIEW_CHARGE]: 'Visualizar cobranças',
	[AccountPermission.UPDATE_CHARGE]: 'Alterar cobranças',
	[AccountPermission.CREATE_BALANCE_RETENTION]: 'Criar bloqueios de saldo',
	[AccountPermission.REMOVE_BALANCE_RETENTION]: 'Remover bloqueios de saldo',
	[AccountPermission.VIEW_BALANCE_RETENTION]: 'Visualizar bloqueios de saldo',
};

export const AccountStatusDescription = {
	[AccountStatus.ACTIVE]: 'Conta ativa',
	[AccountStatus.AGREEMENT_ERROR]: 'Erro no contrato',
	[AccountStatus.AGREEMENT_RENDERED]: 'Contrato gerado',
	[AccountStatus.AWAITING_AGREEMENT_RENDERING]: 'Aguardando contrato',
	[AccountStatus.AWAITING_AGREEMENT_RENDERING_PROCESSING]: 'Gerando contrato',
	[AccountStatus.AWAITING_BAAS_ACCOUNT_CREATION]: 'Aguardando criação',
	[AccountStatus.AWAITING_BACKOFFICE_ANALYSIS]: 'Aguardando análise',
	[AccountStatus.AWAITING_DOCUMENTS_UPDATE]: 'Aguardando documentos',
	[AccountStatus.BAAS_ACCOUNT_CREATION_ERROR]: 'Erro na criação',
	[AccountStatus.BACKOFFICE_ANALYSIS_REFUSED]: 'Conta rejeitada',
	[AccountStatus.BLOCKED]: 'Conta bloqueada',
	[AccountStatus.BLOCKING]: 'Bloqueando',
	[AccountStatus.CANCELLED]: 'Conta encerrada',
	[AccountStatus.CANCELING]: 'Encerrando',
	[AccountStatus.CREATED]: 'Aguardando KYC',
	[AccountStatus.KYC_ERROR]: 'Erro no KYC',
	[AccountStatus.KYC_PENDING]: 'Aguardando KYC',
	[AccountStatus.KYC_REFUSED]: 'KYC rejeitado',
	[AccountStatus.PENDING_SIGNATURE]: 'Pendente de assinatura',
	[AccountStatus.SIGNATURE_ERROR]: 'Erro na assinatura',
	[AccountStatus.UNBLOCKING]: 'Desbloqueando',

	// OLDS - Manter histórico
	// [AccountStatus.CANCELED]: 'Encerrada',
	// [AccountStatus.CREATING]: 'Criando',
	// [AccountStatus.ERROR_BLOCKING]: 'Erro ao bloquear',
	// [AccountStatus.ERROR_CANCELING]: 'Erro ao encerrar',
	// [AccountStatus.ERROR_CREATING]: 'Erro na criação',
	// [AccountStatus.ERROR_UNBLOCKING]: 'Erro ao desbloquear',
	// [AccountStatus.IN_ANALYSIS]: 'Em análise',
	// [AccountStatus.PENDING_ACTIVATION]: 'Pendente de ativação',
	// [AccountStatus.REFUSED]: 'Rejeitado',
	// [AccountStatus.WAITING_ANALYSIS]: 'Pendente de análise',
	// [AccountStatus.WAITING_UPDATES]: 'Aguardando informações',
};

export const AccountStatusDisplay = {
	[AccountStatus.ACTIVE]: {
		label: AccountStatusDescription[AccountStatus.ACTIVE],
		background: theme.successBackground,
		textColor: theme.success,
		statusColor: theme.successLight,
	},
	[AccountStatus.AGREEMENT_ERROR]: {
		label: AccountStatusDescription[AccountStatus.AGREEMENT_ERROR],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.AGREEMENT_RENDERED]: {
		label: AccountStatusDescription[AccountStatus.AGREEMENT_RENDERED],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.AWAITING_AGREEMENT_RENDERING]: {
		label: AccountStatusDescription[
			AccountStatus.AWAITING_AGREEMENT_RENDERING
		],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.AWAITING_AGREEMENT_RENDERING_PROCESSING]: {
		label: AccountStatusDescription[
			AccountStatus.AWAITING_AGREEMENT_RENDERING_PROCESSING
		],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.AWAITING_BAAS_ACCOUNT_CREATION]: {
		label: AccountStatusDescription[
			AccountStatus.AWAITING_BAAS_ACCOUNT_CREATION
		],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.AWAITING_BACKOFFICE_ANALYSIS]: {
		label: AccountStatusDescription[
			AccountStatus.AWAITING_BACKOFFICE_ANALYSIS
		],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.AWAITING_DOCUMENTS_UPDATE]: {
		label: AccountStatusDescription[
			AccountStatus.AWAITING_DOCUMENTS_UPDATE
		],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.BAAS_ACCOUNT_CREATION_ERROR]: {
		label: AccountStatusDescription[
			AccountStatus.BAAS_ACCOUNT_CREATION_ERROR
		],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.BACKOFFICE_ANALYSIS_REFUSED]: {
		label: AccountStatusDescription[
			AccountStatus.BACKOFFICE_ANALYSIS_REFUSED
		],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[AccountStatus.CANCELLED]: {
		label: AccountStatusDescription[AccountStatus.CANCELLED],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[AccountStatus.CREATED]: {
		label: AccountStatusDescription[AccountStatus.CREATED],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.KYC_ERROR]: {
		label: AccountStatusDescription[AccountStatus.KYC_ERROR],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.KYC_PENDING]: {
		label: AccountStatusDescription[AccountStatus.KYC_PENDING],
		statusColor: theme.textGray,
		background: theme.grayLight,
		textColor: theme.textGray,
	},
	[AccountStatus.KYC_REFUSED]: {
		label: AccountStatusDescription[AccountStatus.KYC_REFUSED],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.PENDING_SIGNATURE]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.BLOCKED]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[AccountStatus.BLOCKING]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[AccountStatus.CANCELING]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		background: theme.dangerBackground,
		textColor: theme.dangerText,
		statusColor: theme.danger,
	},
	[AccountStatus.UNBLOCKING]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
	[AccountStatus.SIGNATURE_ERROR]: {
		label: AccountStatusDescription[AccountStatus.PENDING_SIGNATURE],
		statusColor: theme.orange,
		background: theme.orangeSoft,
		textColor: theme.textOrange,
	},
};

export const AccountOwnerPermissions = [
	AccountPermission.VIEW_ACCOUNT,
	AccountPermission.POSTING_VIEW,
	AccountPermission.VIEW_WALLET,
];

export const AccountPermissionFromFundKey = {
	manager: [AccountPermission.VIEW_ACCOUNT, AccountPermission.POSTING_CREATE],
	securitizer: [
		AccountPermission.VIEW_ACCOUNT,
		AccountPermission.POSTING_REVIEW,
	],
	administrator: [AccountPermission.VIEW_ACCOUNT],
};

export const AccountRelationshipFromFundKey = {
	manager: BusinessType.MANAGER,
	securitizer: BusinessType.CUSTODIAN,
	administrator: BusinessType.ADMINISTRATOR,
};

export const AccountDocumentsList = [
	{
		label: 'Contrato de abertura',
		download_key: 'opening_agreement_url',
		upload_key: 'opening_agreement',
	},
	{
		label: 'Comprovante de encerramento',
		download_key: 'canceling_agreement_url',
		upload_key: 'canceling_agreement',
	},
];
