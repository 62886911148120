import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { UploadService } from 'modules/core/services/UploadService';
import { LoggerService } from 'modules/core/services/LoggerService';
import { Table } from 'components/Table';
import { saveAs } from 'file-saver';
import { DocumentsActions } from './styles';
import { Heading2 } from 'components/Heading2';
import { DownloadIcon } from 'components/Icons/Download';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { UploadCloudIcon } from 'components/Icons/Upload';
import { StepHeader } from 'components/NaturalPersonRepresentativesCreation/styles';

type DocumentType = {
	label: string;
	download_key: string;
	upload_key: string;
};

interface IDocumentsProps {
	title?: string;
	hideTitle?: boolean;
	isDisabled?: boolean;
	isCreation?: boolean;
	viewPage?: boolean;
	borderless?: boolean;
	documentsList: DocumentType[];
	documentsObject?: { [key: string]: string };
	onChangeDocumentsObject?: (documentsObject: {
		[key: string]: string;
	}) => void;
	updateDocumentHandler?: (documentsObject: {
		[key: string]: string;
	}) => void;
}
const Documents: React.FC<IDocumentsProps> = ({
	title = 'Documentos',
	hideTitle,
	isDisabled,
	viewPage,
	borderless,
	documentsList,
	documentsObject,
	isCreation,
	onChangeDocumentsObject,
	updateDocumentHandler,
}) => {
	const theme = useTheme();
	const inputFile = useRef<HTMLInputElement>(null);
	const [selectedDocument, setSelectedDocument] =
		useState<DocumentType | null>(null);
	const [isUploading, setIsUploading] = useState(false);

	const onUploadDocument = useCallback(
		async (e: ChangeEvent<HTMLInputElement>) => {
			setIsUploading(true);
			try {
				const file = e.target.files?.[0];
				if (file && selectedDocument && documentsObject) {
					const upload = await UploadService.uploadFile(file);
					const uploadDocumentKeys = documentsList.reduce(
						(u, documentType) => {
							const newUpload: { [key: string]: string } = {};
							if (
								typeof documentsObject[
									documentType.upload_key
								] === 'string'
							) {
								newUpload[documentType.upload_key] =
									documentsObject[documentType.upload_key];
							}
							if (
								typeof documentsObject[
									documentType.download_key
								] === 'string'
							) {
								newUpload[documentType.download_key] =
									documentsObject[documentType.download_key];
							}
							return {
								...u,
								...newUpload,
							};
						},
						{},
					);

					/* Valida se tem chamada de edit do container acima */
					if (typeof updateDocumentHandler === 'function') {
						updateDocumentHandler({
							[selectedDocument.upload_key]: upload.key,
							[selectedDocument.download_key]:
								upload.download_url,
						});
					}

					// HK - Deprecated: ajustar chamadas
					if (typeof onChangeDocumentsObject === 'function') {
						onChangeDocumentsObject({
							...uploadDocumentKeys,
							[selectedDocument.upload_key]: upload.key,
							[selectedDocument.download_key]:
								upload.download_url,
						});
					}
				}
			} catch (e) {
				LoggerService.error(e);
			} finally {
				setIsUploading(false);
			}
		},
		[
			documentsList,
			documentsObject,
			onChangeDocumentsObject,
			selectedDocument,
		],
	);

	const onSendDocument = useCallback(
		async (document: DocumentType) => {
			setSelectedDocument(document);
			inputFile.current?.click();
		},
		[setSelectedDocument],
	);

	const columns: ColumnsType<DocumentType> = useMemo(() => {
		const items: ColumnsType<DocumentType> = [
			{
				title: 'Documento',
				render: (document: DocumentType) => (
					<TextM
						weight="normal"
						style={{ color: theme.textGray }}
					>{`${document.label}`}</TextM>
				),
				width: '50%',
			},
		];

		items.push({
			title: 'Status',
			render: (document: DocumentType) => {
				if (
					!!documentsObject &&
					typeof documentsObject[document.download_key] !==
						'undefined'
				) {
					return 'Enviado';
				}
				return isCreation ? '' : 'Pendente';
			},
			width: '30%',
		});
		items.push({
			align: 'end',
			width: '20%',
			render: (document: DocumentType) => {
				if (
					(!!documentsObject &&
						!isCreation &&
						!documentsObject?.[document?.download_key]) ||
					(!!documentsObject &&
						typeof documentsObject[document.download_key] !==
							'undefined')
				) {
					return (
						<DocumentsActions>
							{!isDisabled && (
								<Button
									type="link"
									disabled={isDisabled}
									onClick={() => onSendDocument(document)}
									loading={
										isUploading &&
										selectedDocument?.upload_key ===
											document.upload_key
									}
								>
									Enviar novamente
								</Button>
							)}
							{!!documentsObject[document.download_key] && (
								<Button
									type="link"
									disabled={false}
									onClick={() => {
										saveAs(
											documentsObject[
												document.download_key
											],
										);
									}}
								>
									<DownloadIcon />
								</Button>
							)}
						</DocumentsActions>
					);
				}
				return (
					!viewPage && (
						<DocumentsActions>
							<Button
								type="dashed"
								disabled={isDisabled}
								onClick={() => onSendDocument(document)}
								loading={
									isUploading &&
									selectedDocument?.upload_key ===
										document.upload_key
								}
								style={{
									borderRadius: '16px',
									padding: '1.2rem 1rem',
								}}
							>
								<UploadCloudIcon />
								<TextS color={theme.primary}>
									Fazer upload
								</TextS>
							</Button>
						</DocumentsActions>
					)
				);
			},
		});

		return items;
	}, [
		documentsObject,
		isDisabled,
		isUploading,
		onSendDocument,
		selectedDocument,
		theme,
	]);

	return (
		<>
			{isCreation && (
				<StepHeader>
					<Heading2
						style={{
							color: theme.primary,
						}}
					>
						Documentos
					</Heading2>
				</StepHeader>
			)}
			<Table
				title={
					!hideTitle
						? () => (
								<Heading2 style={{ paddingLeft: '4px' }}>
									{title}
								</Heading2>
							)
						: undefined
				}
				columns={columns}
				showHeader={!isCreation}
				rowKey={record => record.label}
				borderless={borderless}
				dataSource={documentsList}
				pagination={false}
			/>
			<input
				type="file"
				id="file"
				ref={inputFile}
				accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
				onChange={onUploadDocument}
				style={{ display: 'none' }}
			/>
		</>
	);
};

export default Documents;
