import {
	BreadcrumbItemType,
	BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { ReactNode, useEffect } from 'react';
import { Tabs as TabsAnt } from './styles';
import { useNavigate, useParams } from 'react-router-dom';

const { TabPane } = TabsAnt;

export type TabElement = {
	title: string;
	content: JSX.Element;
	path: string;
	breadcrumb?: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
	icon?: ReactNode;
};

interface ITabsProps {
	elements: Array<TabElement>;
	prePath?: string;
	onChange?: (path: string) => void;
}
const Tabs = ({ elements, prePath, onChange }: ITabsProps) => {
	const navigate = useNavigate();
	const { tab } = useParams();
	const { setBreadcrumb } = useBreadcrumb();

	useEffect(() => {
		const breadcrumb = elements.find(
			element => element.path === tab,
		)?.breadcrumb;
		if (breadcrumb) {
			setBreadcrumb(breadcrumb);
		}
	}, [tab]);

	return (
		<TabsAnt
			activeKey={tab}
			onChange={path => {
				if (onChange) onChange(path);
				navigate(`..${prePath ?? ''}/${path}`);
			}}
		>
			{elements.map(element => (
				<TabPane
					tab={element.title}
					key={element.path}
					icon={element.icon}
				>
					{element.content}
				</TabPane>
			))}
		</TabsAnt>
	);
};

export default Tabs;
