import { SignerCollapseHeader } from './styles';
import { Account } from 'types/Account';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { useMemo } from 'react';
import { Col, Row } from 'antd';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { TextM } from 'components/Text';
import { CompanyOutlinedIcon } from 'components/Icons/Company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { SignerIcon } from 'components/Icons/Signer';
import theme from 'theme/theme';
import ChipComponent from 'components/Chip/Chip';
import { isPersonOrCompany } from 'helpers/validators';
import { EClientType } from 'types/Company';

type SignerType = {
	companyName: string;
	companyTaxpayerId: string;
	signerName: string;
	signerTaxpayerId: string;
	signerEmail: string;
	signed: boolean;
};

interface IEscrowDetailsSignersProps {
	account: Account;
	hideActions?: boolean;
}
const EscrowDetailsSigners = ({
	account,
	hideActions,
}: IEscrowDetailsSignersProps) => {
	const { person: company, parties } = account;
	const ifPF: boolean =
		isPersonOrCompany(account.person.taxpayer_id) === EClientType.PF;

	const companySigner = useMemo(() => {
		const companySigner = company.representatives.find(representative => {
			if (representative.sign_contract) return true;
			if (
				representative.representative &&
				representative.representative.sign_contract
			)
				return true;
			return false;
		});
		if (companySigner?.sign_contract) {
			return {
				companyName: company.name,
				companyTaxpayerId: normalizeCpfCnpj(company.taxpayer_id),
				signerName: companySigner?.full_name || '-',
				signerEmail: companySigner.email_address || '',
				isSigned: companySigner?.signed || false,
				signerTaxpayerId: companySigner?.taxpayer_id
					? normalizeCpfCnpj(companySigner.taxpayer_id)
					: '-',
			};
		}
		return {
			companyName: company.name,
			companyTaxpayerId: normalizeCpfCnpj(company.taxpayer_id),
			signerName: companySigner?.representative?.full_name || '-',
			signerEmail: companySigner?.email_address || '',
			isSigned: companySigner?.signed || false,
			signerTaxpayerId: companySigner?.representative?.taxpayer_id
				? normalizeCpfCnpj(companySigner.representative.taxpayer_id)
				: '-',
		};
	}, [company]);

	const signers = useMemo(() => {
		return parties.reduce<SignerType[]>((allSigners, part) => {
			const representatives = part.person.representatives.filter(
				representative =>
					representative.sign_contract ||
					representative.representative?.sign_contract,
			);

			const newSigners = representatives.map(representative => {
				if (representative.sign_contract) {
					return {
						companyName: part.person?.name || '-',
						companyTaxpayerId: part.person?.taxpayer_id
							? normalizeCpfCnpj(part.person.taxpayer_id)
							: '-',
						signerName: representative?.full_name || '-',
						signerTaxpayerId: representative?.taxpayer_id
							? normalizeCpfCnpj(representative.taxpayer_id)
							: '-',
						signerEmail: representative.email || '',
						signed: representative.signed,
					};
				}
				return {
					companyName: part.person?.name || '-',
					companyTaxpayerId: part.person?.taxpayer_id
						? normalizeCpfCnpj(part.person.taxpayer_id)
						: '-',
					signerName:
						representative?.representative?.full_name || '-',
					signerTaxpayerId: representative?.representative
						?.taxpayer_id
						? normalizeCpfCnpj(
								representative.representative.taxpayer_id,
							)
						: '-',
					signerEmail: representative.representative?.email || '',
					signed: representative.signed,
				};
			});

			if (ifPF) {
				newSigners.push({
					companyName: account.parties[0].person.name,
					companyTaxpayerId: account.parties[0].person.taxpayer_id,
					signed: account.parties[0].signed || false,
					signerEmail: company.email_address,
					signerName: company.name,
					signerTaxpayerId: company.taxpayer_id,
				});
			}

			const uniqueSigners = newSigners.filter(
				newSigner =>
					!allSigners.some(
						existingSigner =>
							existingSigner.signerTaxpayerId ===
							newSigner.signerTaxpayerId,
					),
			);

			return allSigners.concat(uniqueSigners);
		}, []);
	}, [account, companySigner]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Collapse
					items={signers?.map((signer, i) => {
						return {
							key: `${i + 1}`,
							label: (
								<SignerCollapseHeader>
									<CollapseHeader>
										<div style={{ marginRight: '8px' }}>
											<SignerIcon />
										</div>
										<TextM
											style={{
												marginRight: '1rem',
											}}
										>
											{signer.signerName}
										</TextM>
										<ChipComponent
											backgroundColor={theme.background}
											alignText="center"
										>
											{signer.signed ? (
												<small
													style={{
														padding: '0 5px',
														width: '4rem',
														textAlign: 'center',
														color: theme.primary,
													}}
												>
													Assinado
												</small>
											) : (
												<small
													style={{
														padding: '0 5px',
														width: '4rem',
														textAlign: 'center',
														color: theme.dangerText,
													}}
												>
													Pendente
												</small>
											)}
										</ChipComponent>
									</CollapseHeader>
									<CollapseHeader>
										<CompanyOutlinedIcon
											color={theme.primary}
										/>
										<TextM>{signer.companyName}</TextM>
									</CollapseHeader>
								</SignerCollapseHeader>
							),
							children: (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados da empresa"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome da empresa',
													children:
														signer.companyName,
												},
												{
													key: '2',
													label: ifPF
														? 'CPF'
														: 'CNPJ',
													children: normalizeCpfCnpj(
														signer.companyTaxpayerId,
													),
												},
												{
													key: '3',
													label: 'E-mail',
													children:
														signer.signerEmail ||
														'-',
												},
											]}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados do assinante"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome do assinante',
													children: signer.signerName,
												},
												{
													key: '2',
													label: 'CPF',
													children: normalizeCpfCnpj(
														signer.signerTaxpayerId,
													),
												},
												{
													key: '3',
													label: 'E-mail',
													children:
														signer.signerEmail ||
														'-',
												},
											]}
										/>
									</Col>
								</Row>
							),
						};
					})}
				/>
			</Col>
		</Row>
	);
};

export default EscrowDetailsSigners;
