import { Button, Empty, Flex, notification } from 'antd';
import { CollapseProps } from 'antd/lib';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

// Components
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { CustomSwitch } from 'components/CustomSwitch';
import { Heading1 } from 'components/Heading1';
import { PageActions } from 'components/PageActions';
import { PersonsService } from 'modules/company/services';
import { RelatedPartsCreation } from 'modules/escrow/components/RelatedPartsCreation';
import { RelatedPartType } from 'modules/escrow/components/RelatedPartsCreation/RelatedPartsCreation';
import { TextM, TextS } from 'components/Text';
import EmptyRepresentativesList from 'components/images/emptyListA.png';

// Hooks and services
import { useCompany } from 'modules/company/hooks';
import { useCompanyFund } from 'modules/company/hooks/useCompanyFund';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';

// Types and helpers
import { AccessType } from 'types/Access';
import { AccountPermission } from 'types/Account';
import { Company, CompanyFund, BusinessType } from 'types/Company';
import {
	AccountPermissionDescription,
	AccountPermissionFromFundKey,
	AccountRelationshipFromFundKey,
} from 'constants/account';

// Styles
import theme from 'theme/theme';
import {
	ContainerFluid,
	ContainerHeader,
	FinancialInstitutionContainer,
} from '../styles';

const EscrowCreationRelatedParts = () => {
	/* Hooks */
	const [api, contextHolder] = notification.useNotification();
	const { getCompanyById, loading: isCompanyLoading } = useCompany();
	const { person: personInContext, type } = useCurrentProfile();
	const { relatedParts, setRelatedParts } = useEscrowAccountsContext();

	/* States */
	const [editRelatedPart] = useState<RelatedPartType>();
	const [isRelatedPartCreationOpen, setIsRelatedPartCreationOpen] =
		useState(false);

	/* Api calls */
	const { loading: isCompanyFundLoading, getCompanyFund } = useCompanyFund({
		onError: error =>
			api.error({
				description: error.data.message,
				message:
					'Ocorreu um problema ao buscar as informações do fundo.',
			}),
	});

	const { data: personInTheContextData, error } = useQuery({
		queryKey: ['personData', personInContext],
		queryFn: () => PersonsService.getCompany(personInContext?.id ?? ''),
		enabled:
			!!personInContext?.id && type !== AccessType.FINANCIAL_INSTITUTION,
	});

	/* Handlers */
	const onCreateRelatedPart = useCallback(
		(relatedPart: RelatedPartType) => {
			const relatedPartsCompanyIds = relatedParts.map(rp => rp.person_id);

			if (relatedPart.relationship === BusinessType.FUND) {
				getCompanyFund(relatedPart.person_id).then(companyFund => {
					const companyFundRelatedParts = Object.keys(companyFund)
						.filter(
							companyFundKey =>
								companyFund[
									companyFundKey as keyof CompanyFund
								] !== null && companyFundKey !== 'fund',
						)
						.map<RelatedPartType>(companyFundKey => {
							const companyFundItem = companyFund[
								companyFundKey as keyof CompanyFund
							] as Company;
							return {
								person_id: companyFundItem.id,
								person: companyFundItem,
								permission:
									AccountPermissionFromFundKey[
										companyFundKey as keyof typeof AccountPermissionFromFundKey
									],
								relationship:
									AccountRelationshipFromFundKey[
										companyFundKey as keyof typeof AccountPermissionFromFundKey
									],
							};
						})
						.filter(
							companyFundItem =>
								!relatedPartsCompanyIds.includes(
									companyFundItem.person_id,
								),
						);
					setRelatedParts([
						...relatedParts,
						{
							...relatedPart,
							person: companyFund.fund ?? undefined,
						},
						...companyFundRelatedParts,
					]);
					setIsRelatedPartCreationOpen(false);
				});
			} else {
				if (relatedPartsCompanyIds.includes(relatedPart.person_id)) {
					setIsRelatedPartCreationOpen(false);
					return;
				}
				getCompanyById(relatedPart.person_id).then(company => {
					setRelatedParts([
						...relatedParts,
						{
							...relatedPart,
							person: company as Company,
						},
					]);
					setIsRelatedPartCreationOpen(false);
				});
			}
		},
		[getCompanyById, getCompanyFund, relatedParts],
	);

	const handleTogglePermission = useCallback(
		(relatedPart: RelatedPartType, permission: AccountPermission) => {
			const parsedParts = relatedParts.map(rp => {
				if (rp.person_id === relatedPart.person_id) {
					const newPermissions = rp.permission.includes(permission)
						? rp.permission.filter(p => p !== permission)
						: [...rp.permission, permission];

					return {
						...rp,
						permission: newPermissions,
					};
				}

				return rp;
			});

			setRelatedParts(parsedParts);
		},
		[relatedParts],
	);

	const onRemoveRelatedPart = useCallback(
		(relatedPart: RelatedPartType, isMainPart: boolean) => {
			if (relatedPart?.person_id === 'company_owner_id' || isMainPart) {
				api.error({
					message: 'Não é possível remover essa parte relacionada',
				});
				return;
			}

			const parsedParts = relatedParts.filter(
				relatedPartEl =>
					relatedPartEl.person_id !== relatedPart?.person_id,
			);

			setRelatedParts(parsedParts);
		},
		[api],
	);

	const representativesItems: CollapseProps['items'] = useMemo(() => {
		return relatedParts
			.filter(item => item.person_id !== 'celcoin_company_id')
			.map((relatedPart, idx) => ({
				key: `${idx + 1}`,
				label: (
					<CollapseHeader>
						<TextM>{relatedPart.person?.name || '-'}</TextM>
					</CollapseHeader>
				),
				children: (
					<div>
						{Object.values(AccountPermission).map(permission => (
							<div
								key={permission}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '1rem',
									borderBottom: `1px solid ${theme.border}`,
								}}
							>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									{
										AccountPermissionDescription[
											permission as AccountPermission
										]
									}
								</TextS>
								<CustomSwitch
									checked={
										relatedPart.permission?.includes?.(
											permission,
										) || false
									}
									handleChange={() =>
										handleTogglePermission(
											relatedPart,
											permission,
										)
									}
								/>
							</div>
						))}
						<div
							onClick={() =>
								onRemoveRelatedPart(relatedPart, idx === 0)
							}
							style={{
								marginTop: '1rem',
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								cursor: 'pointer',
							}}
						>
							<TextM
								style={{
									color: theme.danger,
								}}
							>
								Remover parte relacionada
							</TextM>
						</div>
					</div>
				),
			}));
	}, [relatedParts, handleTogglePermission, onRemoveRelatedPart]);

	useEffect(() => {
		if (error) {
			return api.error({
				description: error.message,
				message: 'Ocorreu um erro ao buscar os dados do contexto.',
			});
		}
	}, [error]);

	useEffect(() => {
		if (personInTheContextData) {
			const parsedParts: RelatedPartType[] = relatedParts.map(
				relatedPart => {
					if (relatedPart.person_id === personInTheContextData.id) {
						return {
							...relatedPart,
							person: personInTheContextData,
						};
					}
					return relatedPart;
				},
			);

			setRelatedParts(parsedParts);
		}
	}, [personInTheContextData]);

	return (
		<ContainerFluid>
			{contextHolder}
			<ContainerHeader>
				<Heading1
					style={{
						marginBottom: '2.5rem',
					}}
				>
					Partes Relacionadas
				</Heading1>
				<PageActions>
					<Button
						type="primary"
						onClick={() => setIsRelatedPartCreationOpen(true)}
					>
						<Flex
							style={{
								alignItems: 'center',
							}}
						>
							<TextS color={theme.white}>Adicionar parte</TextS>
							<AddCircleIcon color={theme.white} size="18" />
						</Flex>
					</Button>
				</PageActions>
			</ContainerHeader>
			{!relatedParts.length && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						Você ainda não possui parte relacionada cadastrada!
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						description={
							<TextS
								style={{
									paddingTop: '1rem',
									color: theme.textGray,
									textAlign: 'center',
								}}
							>
								Para prosseguir, adicione uma parte relacionada.
							</TextS>
						}
						image={EmptyRepresentativesList}
						imageStyle={{
							height: 200,
						}}
					/>
				</>
			)}
			{relatedParts.length > 0 && (
				<>
					<FinancialInstitutionContainer>
						<TextM
							style={{
								fontSize: '1.12rem',
							}}
						>
							Celcoin Instituição de Pagamento S.A
						</TextM>
						<TextM
							style={{
								fontSize: '1.12rem',
							}}
						>
							Instituição Financeira
						</TextM>
					</FinancialInstitutionContainer>

					<Collapse
						style={{ margin: '1rem 0' }}
						expandIconPosition="end"
						items={representativesItems}
					/>
				</>
			)}

			{/* Modal  */}
			<RelatedPartsCreation
				isOpen={isRelatedPartCreationOpen}
				isLoading={isCompanyFundLoading ?? isCompanyLoading}
				relatedPart={editRelatedPart}
				onClose={() => setIsRelatedPartCreationOpen(false)}
				onCreate={onCreateRelatedPart}
			/>
		</ContainerFluid>
	);
};

export default EscrowCreationRelatedParts;
