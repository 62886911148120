import { Address } from './Address';
import { IdDocument } from './IdDocument';
import { Phone } from './Phone';

export enum RepresentativeRole {
	MANAGING_PARTNER = 'MANAGING_PARTNER',
	MANAGER = 'MANAGER',
	PARTNER = 'PARTNER',
	DIRECTOR = 'DIRECTOR',
}

export type RepresentativeDocuments = {
	identification?: string;
	proof_of_address?: string;
	identification_url?: string;
	proof_of_address_url?: string;
};

export type BaseRepresentative = {
	address: Address;
	birth_date: string;
	documents: RepresentativeDocuments;
	email_address: string;
	full_name: string;
	id_document: IdDocument;
	id?: string;
	mother_name: string;
	pep: boolean;
	phone: Phone;
	role?: RepresentativeRole;
	sign_contract: boolean;
	signed?: boolean;
	taxpayer_id: string;
};

export type Representative = BaseRepresentative & {
	representative?: BaseRepresentative;
};
