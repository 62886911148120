import styled from 'styled-components';

export const StyledWrapperChip = styled.div<{
	color: string;
	justify: string;
	shadow: boolean;
}>`
	background-color: ${({ color }) => color};
	border-radius: 100px;
	box-shadow: 0px 4px 10px 0px ${({ color }) => color};
	display: flex;
	justify-content: ${({ justify }) => justify};
	align-items: center;
	padding: 3px;
	max-width: 14rem;

	p {
		margin-left: 0.5rem;
	}
`;

export const StyledStatusColor = styled.div<{ color: string }>`
	background-color: ${({ color }) => color};
	box-shadow: 0px 3px 10px 0px ${({ color }) => color};
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 100px;
	margin-left: 0.5rem;
`;
