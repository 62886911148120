import React from 'react';

export const UnlockIcon: React.FC<{
	color?: string;
	size?: number | string;
}> = ({ color, size }) => (
	<svg
		fill="none"
		height={size ?? '24'}
		viewBox="0 0 24 24"
		width={size ?? '24'}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill={color}
			d="M7 10H20C20.5523 10 21 10.4477 21 11V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11C3 10.4477 3.44772 10 4 10H5V9C5 5.13401 8.13401 2 12 2C14.7405 2 17.1131 3.5748 18.2624 5.86882L16.4731 6.76344C15.6522 5.12486 13.9575 4 12 4C9.23858 4 7 6.23858 7 9V10ZM5 12V20H19V12H5ZM10 15H14V17H10V15Z"
		></path>
	</svg>
);
