import { BusinessType } from 'types/Company';

export const CompanyTypeDescription = {
	[BusinessType.FUND]: 'Fundo',
	[BusinessType.MANAGER]: 'Gestor',
	// [CompanyType.FINANCIAL_INSTITUTION]: "Instituição Financeira",
	[BusinessType.SECURITIZER]: 'Securitizadora',
	[BusinessType.CONSULTANT]: 'Consultor',
	[BusinessType.CUSTODIAN]: 'Custodiante',
	[BusinessType.ADMINISTRATOR]: 'Administrador',
	[BusinessType.OTHERS]: 'Outros',
};

export const CompanyDocumentsList = [
	{
		label: '* Contrato Social',
		download_key: 'incorporation_certificate_url',
		upload_key: 'incorporation_certificate',
	},
	{
		label: '* Comprovante de endereço',
		download_key: 'proof_of_address_url',
		upload_key: 'proof_of_address',
	},
	{
		label: '* Faturamento',
		download_key: 'income_statement_url',
		upload_key: 'income_statement',
	},
	{
		label: '* Última alteração contratual',
		download_key: 'last_contract_amendment_url',
		upload_key: 'last_contract_amendment',
	},
	{
		label: 'Ata de eleição de diretoria',
		download_key: 'bylaws_url',
		upload_key: 'bylaws',
	},
	{
		label: 'Procuração',
		download_key: 'power_of_attorney_url',
		upload_key: 'power_of_attorney',
	},
];

export const naturalPersonDocumentsList = [
	{
		label: '* Comprovante de endereço',
		download_key: 'proof_of_address_url',
		upload_key: 'proof_of_address',
	},
	{
		label: '* Documento de identificação',
		download_key: 'identification_url',
		upload_key: 'identification',
	},
];

export const CELCOIN_NAME = 'Celcoin Instituição de Pagamento S.A.';
export const CELCOIN_CNPJ = '13.935.893/0001-09';
export const CELCOIN_BANK_ISPB = '13935893';
