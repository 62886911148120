import {
	Account,
	AccountPermission,
	AccountRelatedPartDetailed,
	AccountStatus,
} from 'types/Account';
import { AccountPermissionDescription } from 'constants/account';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { CollapseProps, Flex, notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { TextM, TextS } from 'components/Text';
import { CustomSwitch } from 'components/CustomSwitch';
import { useTheme } from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AccountsService } from 'modules/escrow/services';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { CompanyTypeDescription } from 'constants/company';
import { BusinessType } from 'types/Company';
import { FinancialInstitutionContainer } from './styles';

interface IEscrowDetailsRelatedPartsProps {
	account: Account;
}

interface AccountRelatedPartDetailedEdit extends AccountRelatedPartDetailed {
	id: string;
}

const EscrowDetailsRelatedParts: React.FC<IEscrowDetailsRelatedPartsProps> = ({
	account,
}) => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const {
		type,
		financial_institution: { name },
	} = useCurrentProfile();
	const [relatedParts, setRelatedParts] = useState<
		AccountRelatedPartDetailedEdit[]
	>((account?.parties as AccountRelatedPartDetailedEdit[]) || []);

	const financialInstitution = {
		id: 'financial_institution',
		person: {
			id: 'financial_institution',
			business_type: '' as BusinessType,
			name: name,
		},
		sign_contract: true,
		permissions: Object.values(AccountPermission),
	};
	const handleTogglePermission = useCallback(
		(
			relatedPart: AccountRelatedPartDetailedEdit,
			permission: AccountPermission,
		) => {
			// if (account.status !== AccountStatus.AWAITING_BACKOFFICE_ANALYSIS) {
			// 	api.info({
			// 		message:
			// 			'Não é possível alterar as permissões, conta não está em análise',
			// 	});
			// 	return;
			// }

			if (type !== AccessType.FINANCIAL_INSTITUTION) {
				api.info({
					message:
						'Não é possível alterar as permissões, Você não tem permissão para essa ação',
				});
				return;
			}

			let updatedRelatedPart = relatedPart;

			setRelatedParts(relatedParts =>
				relatedParts.map(rp => {
					if (rp.id === relatedPart.id) {
						const newPermissions = rp.permissions.includes(
							permission,
						)
							? rp.permissions.filter(p => p !== permission)
							: [...rp.permissions, permission];

						updatedRelatedPart = {
							...rp,
							permissions: newPermissions,
						};

						return {
							...rp,
							permissions: newPermissions,
						};
					}
					return rp;
				}),
			);

			mutate(updatedRelatedPart);
		},
		[],
	);

	// const onRemoveRelatedPart = useCallback(
	// 	(relatedPart: AccountRelatedPartDetailedEdit, isMainPart: boolean) => {
	// 		if (relatedPart?.person.id === 'company_owner_id' || isMainPart) {
	// 			api.error({
	// 				message: 'Não é possível remover essa parte relacionada',
	// 			});
	// 			return;
	// 		}
	// 		setRelatedParts(item =>
	// 			item.filter(
	// 				relatedPartEl =>
	// 					relatedPartEl.person.id !== relatedPart?.person.id,
	// 			),
	// 		);
	// 	},
	// 	[api],
	// );

	const { mutate } = useMutation<
		void,
		ApiError,
		AccountRelatedPartDetailedEdit
	>({
		mutationFn: party => {
			return AccountsService.upsertAccountParty(account.id, party);
		},
		onError: () => {
			api.error({
				message: 'Erro ao atualizar parte relacionada',
			});

			setRelatedParts(
				account.parties as AccountRelatedPartDetailedEdit[],
			);
		},
	});

	const representativesItems: CollapseProps['items'] = useMemo(() => {
		return relatedParts.map((relatedPart, idx) => ({
			key: `${idx + 1}`,
			label: (
				<CollapseHeader>
					<Flex justify="space-between" style={{ width: '100%' }}>
						<TextM>{relatedPart.person?.name || '-'}</TextM>
						<TextM
							style={{
								margin: '0 4rem',
							}}
						>
							{relatedPart.person.id === account.person_id
								? 'Titular'
								: CompanyTypeDescription[
										relatedPart.person.business_type
									]}
						</TextM>
					</Flex>
				</CollapseHeader>
			),
			children:
				relatedPart.id !== 'financial_institution' ? (
					<div>
						{Object.values(AccountPermission).map(permission => (
							<div
								key={permission}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '1rem',
									borderBottom: `1px solid ${theme.border}`,
								}}
							>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									{
										AccountPermissionDescription[
											permission as AccountPermission
										]
									}
								</TextS>
								<CustomSwitch
									checked={
										relatedPart.permissions?.includes?.(
											permission,
										) || false
									}
									handleChange={() =>
										handleTogglePermission(
											relatedPart,
											permission,
										)
									}
								/>
							</div>
						))}
						{/* <div
						onClick={() =>
							onRemoveRelatedPart(relatedPart, idx === 0)
						}
						style={{
							marginTop: '1rem',
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<TextM
							style={{
								color: theme.danger,
							}}
						>
							Remover parte relacionada
						</TextM>
					</div> */}
					</div>
				) : null,
		}));
	}, [account, handleTogglePermission, theme, relatedParts]);

	return (
		<>
			{contextHolder}
			<FinancialInstitutionContainer>
				<Flex justify="space-between" style={{ width: '100%' }}>
					<TextM
						style={{
							fontSize: '18px',
						}}
					>
						{financialInstitution.person?.name || '-'}
					</TextM>
					<TextM
						style={{
							fontSize: '18px',
							margin: '0 6rem',
						}}
					>
						Instituição Financeira
					</TextM>
				</Flex>
			</FinancialInstitutionContainer>
			<Collapse
				style={{ margin: '1rem 0' }}
				expandIconPosition="end"
				items={representativesItems}
			/>
		</>
	);
};

export default EscrowDetailsRelatedParts;
