import dayjs from 'dayjs';
import { formatCurrency } from 'helpers/normalizers';
import { AccountFeeType } from 'types/Account';

/**
 * Formata uma data no formato 'YYYY-MM' para o nome completo do mês em português.
 * @param dateStr - A string da data no formato 'YYYY-MM'.
 * @returns O nome do mês em português.
 */
export const formatMonth = (dateStr: string): string => {
	const [year, month] = dateStr.split('-');
	const date = dayjs(`${year}-${month}-01`);
	return capitalizeFirstLetter(date.format('MMMM'));
};

/**
 * Transforma a primeira letra de uma palavra em maiúsculo.
 * @param word - A palavra a ser transformada.
 * @returns A palavra com a primeira letra em maiúsculo.
 */
export const capitalizeFirstLetter = (word: string): string => {
	if (!word) return word;
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getFormatterFee = (
	value?: number | string,
	type?: AccountFeeType,
) => {
	if (!value) {
		return '-';
	}

	if (type === AccountFeeType.FIXED) {
		return formatCurrency(parseFloat(value.toString()));
	}

	if (type === AccountFeeType.PERCENT) {
		return `${parseFloat(value.toString())} %`;
	}

	return '-';
};

export const isPf = (taxId: string) => {
	const cleanTaxId = taxId.replaceAll('-', '').replaceAll('.', '');

	return !!(cleanTaxId.length === 11);
};
