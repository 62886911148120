const theme = {
	attention: '#f97800',
	backgroundGray: '#F7F7F7',
	backgroundVariant: '#fbf9f9',
	bodyDark: '#eaeaea',
	border: '#EDEDED', // Design name: Border Primary
	inputBorder: '#d9d9d9',
	danger: '#f50404',
	dangerBg: '#ffeaea',
	dangerBackground: '#FCB9B9',
	dangerDisabled: '#9F0B0B',
	dangerOpacity: '#FFEAEA',
	dangerText: '#9f0b0b',
	error: '#ff4d4f',
	placeholder: '#0000001a', // Design name: rgba(0, 0, 0, 0.1)
	placeholderOpacity: '#00000066',
	placeholderSelect: '#c6c5cf',
	primary: '#7664FA',
	primaryOpacity: '#F4F4FF',
	scrollColor: '#3a343338',
	scrollColorHover: '#3a343352',
	success: '#246b30',
	successHighlight: '#00cc21',
	successLight: '#70C7A9',
	successBackground: '#B8F1C1',
	textDisabled: '#9390a3',
	textGray: '#6F6B82', // Design name:  Text Light
	textSecondary: '#302D3F', // Design name:  Neutral
	white: '#ffffff',
	borderSecondary: '#9390A3',
	orangeSoft: '#FDD9B8',
	orange: '#F97800',
	textOrange: '#B95A00',
	grayLight: '#e1e1e1',

	/* @duplicated */
	background: '#3a34330d',
	borderLight: '#3a34330d',

	/* @duplicated */
	text: '#1E1360', // Text Base
	secondary: '#1e1360', // Text Base

	font: {
		primary: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif",
	},
};

export type Theme = typeof theme;
export default theme;
