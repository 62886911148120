import { BlobProvider } from '@react-pdf/renderer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import {
	Button,
	Col,
	DatePicker,
	Dropdown,
	Flex,
	Row,
	Select,
	Space,
	Spin,
	notification,
} from 'antd';
import { MenuProps } from 'antd/lib';
import { ColumnsType } from 'antd/es/table';
import { MdDownload } from 'react-icons/md';
import { MdVisibility } from 'react-icons/md';
import { useTheme } from 'styled-components';

import { ButtonFlex } from 'components/FilterButton/styles';
import { DownloadIcon } from 'components/Icons/Download';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';
import {
	TableParams,
	DateRange,
	defaultTableConfig,
	BLOCKED_ACCOUNT_ERROR,
} from './EscrowStatement';
import { TextM, TextS } from 'components/Text';
import { useAccountStatement } from 'modules/escrow/hooks/useAccountStatement';
import DateFilterDrawer, { DateFilterType } from './DateFilterDrawer';
import ExportedStatementModal from './ExportedStatementModal';
import StatementDetailDrawer from './StatementDetailDrawer';
import StatementReceiptPdf from './StatementReceiptPdf/StatementReceiptPdf';

import {
	AccountBalanceTableWrapper,
	ButtonsSpaces,
	ListWrapper,
	PageWrapper,
} from './styles';

import {
	AccountStatementMovement,
	AccountStatementMovementType,
	IStatementExportPayload,
	IStatementExportResponse,
	StatementStatusResponse,
} from 'types/Account';
import { getStatementMovementType } from 'modules/escrow/utils/statementDescription';
import { formatCurrency } from 'helpers/normalizers';
import { AccountsService, PostingsService } from 'modules/escrow/services';
import { ApiError } from 'types/ApiError';
import { InfinityScroll } from 'components/InfinityScroll';
import { RangePickerButton } from 'components/RangePickerButton';
import { FaArrowUp } from 'react-icons/fa6';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { List } from 'types/List';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import StatementBoletoReceiptPdf from './StatementBoletoReceiptPdf/StatementBoletoReceiptPdf';
import { PostingStatus } from 'types/Posting';
import BlobProviderComponent from './BlobProviderComponent';
import { ExportStatementFileTypeEnum } from './types';
import { fixPhraseFromBaas } from 'helpers/validators';

const AVAILABLE_DETAILS = [
	AccountStatementMovementType.PIXPAYMENTIN,
	AccountStatementMovementType.PIXPAYMENTOUT,
	AccountStatementMovementType.TEDTRANSFERIN,
	AccountStatementMovementType.TEDTRANSFEROUT,
	AccountStatementMovementType.BILLPAYMENT,
];

const INITIAL_DATES_VALUES = {
	dateFrom: dayjs().subtract(6, 'days'),
	dateTo: dayjs(),
};

export const exportFileTypeOptions = [
	{
		label: 'PDF',
		value: ExportStatementFileTypeEnum.PDF,
	},
	{
		label: 'CSV',
		value: ExportStatementFileTypeEnum.CSV,
	},
	{
		label: 'OFX',
		value: ExportStatementFileTypeEnum.OFX,
	},
];

const StatementContent = () => {
	const [api, contextHolder] = notification.useNotification();
	const { id: entityId } = useParams();
	const theme = useTheme();
	dayjs.locale('pt-br');
	const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
	const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
	const [isExportLoading, setIsExportLoading] = useState(false);
	const [isExported, setIsExported] = useState(false);
	const [isExportStatementOpen, setIsExportStatementOpen] = useState(false);
	const [exports, setExports] = useState<string | string[]>([]);
	const [userEmail, setUserEmail] = useState<string>();
	const [dateRangeFilter, setDateRangeFilter] =
		useState<DateRange>(INITIAL_DATES_VALUES);
	const [formattedStatements, setFormattedStatements] = useState<
		AccountStatementMovement[]
	>([]);
	const [movementDetailsData, setMovementDetailsData] =
		useState<StatementStatusResponse>();
	const [selectedMovement, setSelectedMovement] =
		useState<AccountStatementMovement>();
	const [statementForDetail, setStatementForDetail] =
		useState<AccountStatementMovement>();
	const [tableParams, setTableParams] =
		useState<TableParams>(defaultTableConfig);
	const [exportFileType, setExportFileType] =
		useState<ExportStatementFileTypeEnum>(ExportStatementFileTypeEnum.CSV);

	let isExportOptionEmpty = exports.length === 0;

	const { fetchAccountStatementDetails, fetchAccountDetailsLoading } =
		useAccountStatement({
			onSuccess: data => {
				setMovementDetailsData(data);
			},
			onError: error => {
				api.error({
					message: 'Erro ao gerar comprovante',
					description: error?.data?.message,
				});
			},
		});

	const {
		data: postingDetailList,
		isLoading: postingDetailListLoading,
		error: postingDetailListError,
	} = useQuery({
		queryKey: ['postingDetails', movementDetailsData],
		queryFn: () =>
			PostingsService.listPostings({
				billpayment_txn_id_auth:
					movementDetailsData!.transaction_id_authorize!,
				limit: 1,
				page: 1,
				status: PostingStatus.SUCCESS,
			}),
		enabled: movementDetailsData?.transaction_id_authorize !== undefined,
	});

	const handleClearFilters = useCallback(() => {
		setDateRangeFilter(INITIAL_DATES_VALUES);
		setTableParams(defaultTableConfig);

		setFormattedStatements([]);

		setExports([]);
	}, [dateRangeFilter, tableParams]);

	const handlePageChange = (page: number, pageSize: number) => {
		setTableParams(prevState => {
			return {
				...prevState,
				pagination: {
					...prevState.pagination,
					current: page,
					pageSize: pageSize,
				},
			};
		});
	};

	const handleDateFilter = useCallback((filter: DateRange) => {
		setFormattedStatements([]);

		setTableParams(prevState => {
			return {
				...prevState,
				pagination: {
					...prevState.pagination,
					current: 1,
				},
			};
		});

		setDateRangeFilter(filter);
	}, []);

	const formatAccountStatement = (
		data: AccountStatementMovement[] | undefined,
	) => {
		if (!data) {
			return;
		}

		let movements: AccountStatementMovement[] = [];

		const dateRange: string[] = data.reduce((acc: string[], movement) => {
			const date = dayjs(movement.create_date).format('DD-MM-YYYY');
			if (!acc.includes(date)) {
				acc.push(date);
			}
			return acc;
		}, []);

		dateRange.forEach(date => {
			const dailyMovements = data.filter(
				movement =>
					dayjs(movement.create_date).format('DD-MM-YYYY') === date,
			);

			const dailyBalanceMovement: AccountStatementMovement = {
				id: `daily-balance-${date}`,
				create_date: dayjs(dailyMovements[0].create_date)
					.minute(59)
					.hour(23)
					.toISOString(),
				description: `Saldo do dia ${dayjs(
					dailyMovements[0].create_date,
				).format('DD-MM-YYYY')}`,
				amount:
					typeof dailyMovements?.[0]?.balance === 'number'
						? dailyMovements?.[0]?.balance
						: null,
				balance_type: '',
				balance: dailyMovements?.[0].balance,
				client_code: '',
				last_update_date: '',
				movement_type: '' as AccountStatementMovementType,
				status: '',
				additional_information: undefined,
			};

			const dailyBalanceAlreadyExists = formattedStatements.some(
				movement => movement.id === dailyBalanceMovement.id,
			);

			movements = [
				...movements,
				...(dailyBalanceAlreadyExists ? [] : [dailyBalanceMovement]),
				...dailyMovements,
			];
		});

		setFormattedStatements(state => {
			return [...state, ...movements];
		});
	};

	const createActionItems = useCallback(
		(statement: AccountStatementMovement): MenuProps['items'] => {
			const defaultItems: MenuProps['items'] = [];

			defaultItems.push({
				label: 'Ver detalhes',
				icon: <MdVisibility size={18} />,
				key: '1',
				onClick: () => {
					setStatementForDetail(statement);
				},
				disabled:
					!statement.client_code ||
					!AVAILABLE_DETAILS.includes(statement.movement_type),
			});

			if (
				AVAILABLE_DETAILS.includes(statement.movement_type) &&
				statement.client_code
			) {
				defaultItems.push({
					label: 'Baixar comprovante',
					icon: <MdDownload size={18} />,
					key: '2',
					disabled: fetchAccountDetailsLoading,
					onClick: async () => {
						fetchAccountStatementDetails({
							accountId: entityId || '',
							clientCode: statement.client_code,
						});
						setSelectedMovement(statement);
					},
				});
			}

			return defaultItems;
		},
		[entityId, fetchAccountStatementDetails, fetchAccountDetailsLoading],
	);

	const closeExportModal = () => {
		setExports([]);
		setIsExportStatementOpen(false);
	};

	const handleExport = async (exportList: string | string[]) => {
		if (typeof exportList === 'string') {
			exportList = [exportList];
		}

		try {
			setIsExportLoading(true);
			const response = await mutateAsync({
				exportList,
				fileType: exportFileType,
			});

			if (response) {
				setUserEmail(response?.email);
			}
		} catch (error) {
			console.error('Erro na exportação:', error);
		} finally {
			setIsExportLoading(false);
		}
	};

	const scrollToTop = () => {
		const templateContainer = document.querySelector(
			'.default-template-content',
		);
		templateContainer?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		const templateContainer = document.querySelector(
			'.default-template-content',
		);

		if (!templateContainer) {
			return;
		}

		const handleScroll = () => {
			if (templateContainer.scrollTop > 100) {
				setScrollToTopVisible(true);
			} else {
				setScrollToTopVisible(false);
			}
		};

		templateContainer.addEventListener('scroll', handleScroll);

		return () => {
			templateContainer.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { data, isLoading, isFetching, error } = useQuery<
		List<AccountStatementMovement>,
		ApiError
	>({
		queryKey: ['accountStatement', entityId, dateRangeFilter, tableParams],
		queryFn: () =>
			AccountsService.getStatement(entityId || '', {
				date_from: dateRangeFilter.dateFrom.format('YYYY-MM-DD'),
				date_to: dateRangeFilter.dateTo.format('YYYY-MM-DD'),
				page: tableParams.pagination?.current,
				size: tableParams.pagination?.pageSize,
			}),
		staleTime: 5000,
	});

	const { mutateAsync } = useMutation<
		IStatementExportResponse,
		ApiError,
		{ exportList: string[]; fileType: ExportStatementFileTypeEnum }
	>({
		mutationFn: ({ exportList, fileType }) => {
			const dateArray: string[] = [];

			exportList.map(month => {
				dateArray.push(`${month}-05`);
			});

			const payload: IStatementExportPayload = {
				exportedDates: dateArray,
				format: fileType,
			};

			return AccountsService.getStatementExport(entityId || '', payload);
		},
		onSuccess: () => {
			closeExportModal();
			setIsExported(true);
		},
		onError: (e: ApiError) => {
			api.error({
				description: e.data.message || '',
				message: 'Ocorreu um problema ao exportar.',
			});
		},
	});

	useEffect(() => {
		formatAccountStatement(data?.content);
	}, [data]);

	useEffect(() => {
		if (error && error?.data?.message !== BLOCKED_ACCOUNT_ERROR) {
			api.error({
				message: 'Erro ao buscar extrato',
				description: 'Ocorreu um erro ao buscar o extrato da conta',
			});
		}
	}, [error, api]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setMovementDetailsData(undefined);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	});

	useEffect(() => {
		const observer = new IntersectionObserver(
			entries => {
				entries.forEach(entry => {
					if (entry.target.classList.contains('daily-balance')) {
						if (!entry.isIntersecting) {
							const HEADER_MARGIN = 12.8;
							const headerElement = document.getElementById(
								'account-info-balance-header',
							) as HTMLElement;
							const targetElement = entry.target as HTMLElement;
							const headerHeight = headerElement?.clientHeight;
							targetElement.style.top = `${headerHeight + HEADER_MARGIN}px`;
							targetElement.style.position = 'sticky';
							targetElement.style.zIndex = '99';
						}
					}
				});
			},
			{
				rootMargin: '0px',
				threshold: 1,
			},
		);

		const dailyBalanceRows = document.querySelectorAll('.daily-balance');
		dailyBalanceRows.forEach(row => observer.observe(row));

		return () => {
			dailyBalanceRows.forEach(row => observer.unobserve(row));
		};
	}, [formattedStatements]);

	const columns: ColumnsType<AccountStatementMovement> = [
		{
			title: 'Data',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return (
						<TextS
							style={{
								color: theme.primary,
								fontWeight: 600,
							}}
						>
							Dia {dayjs(item.create_date).format('DD/MM/YYYY')}
						</TextS>
					);
				}

				return (
					<TextS
						style={{
							color: theme.textGray,
						}}
					>
						{dayjs(item.create_date).format('DD/MM/YYYY')} às{' '}
						{dayjs(item.create_date).format('HH:mm:ss')}
					</TextS>
				);
			},
			width: '25%',
		},
		{
			title: 'Lançamento',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return '';
				}

				return (
					<TextS
						style={{
							color: theme.textGray,
						}}
					>
						{getStatementMovementType(item) || '-'}
					</TextS>
				);
			},
			width: '30%',
		},
		{
			title: 'Descrição',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return '';
				}
				return (
					<TextS>
						<pre
							style={{
								color: theme.textGray,
								fontFamily: theme.font.primary,
							}}
						>
							{fixPhraseFromBaas(item.description) || '-'}
						</pre>
					</TextS>
				);
			},
			width: '30%',
		},
		{
			title: 'Valor',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return (
						<TextM
							style={{
								color: theme.primary,
							}}
						>
							{item.balance != null
								? formatCurrency(item.balance!)
								: ''}
						</TextM>
					);
				}

				return (
					<TextM
						style={{
							fontFamily: "'Inter', 'sans-serif'",
							color:
								item.balance_type === 'CREDIT'
									? theme.successHighlight
									: theme.danger,
						}}
					>
						{item.balance_type === ''
							? formatCurrency(item.amount!)
							: item.balance_type === 'CREDIT'
								? `+${formatCurrency(item.amount!)}`
								: formatCurrency(item.amount! * -1)}
					</TextM>
				);
			},
			width: '15%',
		},
		{
			title: 'Ações',
			width: '5%',
			render: (item: AccountStatementMovement) => {
				if (item.id.includes('daily-balance')) {
					return '';
				}

				return (
					<Dropdown
						disabled={false}
						menu={{ items: createActionItems(item) }}
						trigger={['click']}
					>
						<Button
							type="link"
							style={{ color: theme.text, fontWeight: 'bold' }}
							loading={false}
						>
							. . .
						</Button>
					</Dropdown>
				);
			},
		},
	];

	const onChange = (date: Date, dateString: string | string[]) => {
		setExports(dateString);
	};

	const listExportOptions = (
		<>
			<TextM color={theme.textSecondary} style={{ margin: '1rem 0' }}>
				Selecione os meses de exportação:
			</TextM>
			<DatePicker
				onChange={onChange}
				picker="month"
				multiple
				maxDate={dayjs()}
			/>

			<TextM color={theme.textSecondary} style={{ margin: '1rem 0' }}>
				Formato do arquivo:
			</TextM>
			<Select
				style={{
					width: '100%',
				}}
				placeholder="Selecione"
				value={exportFileType}
				options={exportFileTypeOptions}
				onChange={value => setExportFileType(value)}
			/>
		</>
	);

	return (
		<div>
			{contextHolder}
			{selectedMovement &&
				movementDetailsData &&
				((selectedMovement.movement_type ===
					AccountStatementMovementType.BILLPAYMENT &&
					postingDetailList) ||
					selectedMovement.movement_type !==
						AccountStatementMovementType.BILLPAYMENT) && ( //avoiding trigger download twice
					<BlobProviderComponent
						documentToBlob={
							selectedMovement.movement_type ===
							AccountStatementMovementType.BILLPAYMENT ? (
								<StatementBoletoReceiptPdf
									movementData={movementDetailsData}
									postingDetails={
										postingDetailList?.content?.[0]
									}
								/>
							) : (
								<StatementReceiptPdf
									movementStatus={movementDetailsData}
									movement={selectedMovement}
								/>
							)
						}
						documentTitle={
							selectedMovement.movement_type ===
							AccountStatementMovementType.BILLPAYMENT
								? `Comprovante_de_pagamento_${movementDetailsData?.id}.pdf`
								: `Comprovante_Transferencia_${movementDetailsData?.id}.pdf`
						}
					/>
				)}

			<PageWrapper>
				{error && error?.data?.message === BLOCKED_ACCOUNT_ERROR ? (
					<>
						<EmptyFiltersResult
							title="Conta bloqueada"
							description="Essa conta está bloqueada. Não é possível visualizar o extrato de uma conta bloqueada."
						/>
					</>
				) : (
					<>
						<Row
							gutter={[8, 8]}
							style={{
								justifyContent: 'space-between',
								alignItems: 'flex-end',
							}}
						>
							<Col
								style={{
									display: 'flex',
									alignItems: 'flex-end',
									gap: '12px',
								}}
							>
								<Flex
									style={{
										flexDirection: 'column',
									}}
									gap={8}
								>
									<TextS weight={600}>
										Filtrar por período
									</TextS>
									<RangePickerButton
										initialDate={dateRangeFilter.dateFrom.format(
											'DD/MM/YYYY',
										)}
										finalDate={dateRangeFilter.dateTo.format(
											'DD/MM/YYYY',
										)}
										onClick={() =>
											setIsDateFilterOpen(true)
										}
									/>
								</Flex>
								<Flex>
									<FilterButton
										icon={
											<AiOutlineStop
												size={18}
												color={theme.white}
											/>
										}
										variation="secondary"
										onClick={handleClearFilters}
										disabled={
											!Object.keys(dateRangeFilter).length
										}
									>
										<TextS style={{ color: theme.white }}>
											Limpar filtros
										</TextS>
									</FilterButton>
								</Flex>
							</Col>
							<Col>
								<ButtonFlex
									type="primary"
									size="middle"
									style={{ gap: '0.5rem', minWidth: '11rem' }}
									onClick={() =>
										setIsExportStatementOpen(true)
									}
								>
									<TextS style={{ color: theme.white }}>
										Exportar extrato
									</TextS>
									<DownloadIcon
										color={theme.white}
										size="18"
									/>
								</ButtonFlex>
							</Col>
						</Row>

						<AccountBalanceTableWrapper>
							<Table
								style={{ marginTop: '1rem' }}
								columns={columns}
								rowKey={record => record.id}
								dataSource={formattedStatements}
								loading={isLoading}
								locale={{
									emptyText: 'Nenhum documento adicionado',
								}}
								rowClassName={record => {
									return record.id.includes('daily-balance')
										? `daily-balance ${record.id}`
										: '';
								}}
								pagination={false}
							/>

							{!isFetching && !data?.has_next && (
								<TextS
									style={{
										textAlign: 'center',
										marginTop: '1rem',
										color: theme.textGray,
									}}
								>
									Não há mais registros
								</TextS>
							)}

							<div
								style={{
									position: 'relative',
									height: '100px',
								}}
							>
								<InfinityScroll
									isLoading={isFetching}
									onRequest={() => {
										if (isFetching) return;
										if (data?.has_next) {
											handlePageChange(
												(tableParams.pagination
													?.current || 0) + 1,
												tableParams.pagination
													?.pageSize!,
											);
										}
									}}
								/>
							</div>
						</AccountBalanceTableWrapper>
					</>
				)}
			</PageWrapper>
			<DateFilterDrawer
				isOpen={isDateFilterOpen}
				onClose={() => setIsDateFilterOpen(false)}
				handleFilter={handleDateFilter}
				filterType={DateFilterType.STATEMENT}
			/>
			{statementForDetail && (
				<StatementDetailDrawer
					isOpen={!!statementForDetail}
					onClose={() => setStatementForDetail(undefined)}
					accountId={entityId}
					statement={statementForDetail}
				/>
			)}

			{isExportStatementOpen && (
				<Modal
					isOpen
					onClose={closeExportModal}
					title="Exportar extrato"
					width={600}
					style={{
						padding: '10px 24px 24px 24px',
					}}
				>
					<ListWrapper>
						{isExportLoading ? (
							<Spin
								style={{
									marginTop: '1rem',
									width: '100%',
								}}
							/>
						) : (
							listExportOptions
						)}
					</ListWrapper>

					<ButtonsSpaces>
						<Space size="large">
							<ButtonFlex
								onClick={closeExportModal}
								size="large"
								style={{ gap: '0.5rem', minWidth: '5rem' }}
								type="text"
							>
								<TextS style={{ color: theme.primary }}>
									Cancelar
								</TextS>
							</ButtonFlex>
							<Button
								disabled={isExportOptionEmpty}
								onClick={() => handleExport(exports)}
								size="large"
								style={{ gap: '0.5rem', minWidth: '5rem' }}
								type="primary"
							>
								<TextS
									color={
										isExportOptionEmpty
											? theme.primary
											: theme.white
									}
									style={{
										opacity: isExportOptionEmpty
											? '0.5'
											: undefined,
									}}
								>
									Exportar
								</TextS>
							</Button>
						</Space>
					</ButtonsSpaces>
				</Modal>
			)}

			{isExported && (
				<ExportedStatementModal
					emailInfo={userEmail}
					setIsExported={setIsExported}
				/>
			)}
			{scrollToTopVisible && !isFetching && (
				<Button
					onClick={scrollToTop}
					style={{
						right: '2rem',
						bottom: '3rem',
						position: 'fixed',
						zIndex: 1000,
						backgroundColor: theme.primary,
						border: 'none',
						outline: 'none',
						borderRadius: '50%',
						width: '40px',
						height: '40px',
					}}
				>
					<FaArrowUp color={theme.white} />
				</Button>
			)}
		</div>
	);
};

export default StatementContent;
