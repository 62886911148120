import React from 'react';

export const EditIcon: React.FC<{ color?: string; size?: string | number }> = ({
	color,
	size,
}) => (
	<svg
		width={size ?? '18'}
		height={size ?? '17'}
		viewBox="0 0 18 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.4173 6.3794L11.1212 5.08323L2.58333 13.6211V14.9172H3.8795L12.4173 6.3794ZM13.7135 5.08323L15.0097 3.78706L13.7135 2.4909L12.4173 3.78706L13.7135 5.08323ZM4.6385 16.7506H0.75V12.8611L13.0654 0.545731C13.2373 0.373883 13.4704 0.277344 13.7135 0.277344C13.9566 0.277344 14.1897 0.373883 14.3616 0.545731L16.9548 3.13898C17.1267 3.31088 17.2232 3.544 17.2232 3.78706C17.2232 4.03013 17.1267 4.26325 16.9548 4.43515L4.63942 16.7506H4.6385Z"
			fill={color ?? 'white'}
		/>
	</svg>
);
