import { Button, DescriptionsProps } from 'antd';
import {
	Descriptions,
	DescriptionsActions,
	DescriptionsContainer,
	DescriptionsHeader,
} from './styles';
import { Heading2 } from 'components/Heading2';
import { FaRegCopy } from 'react-icons/fa';
import { BiSolidEditAlt } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import { ReactNode } from 'react';

interface IDescriptionsVerticalProps {
	title: string | ReactNode;
	items: DescriptionsProps['items'];
	hideActions?: boolean;
	borderless?: boolean;
	columns?: number;
	extraContent?: ReactNode;
	onEditComponent?: ReactNode;
	onEdit?: () => void;
	onCopy?: () => void;
}
const DescriptionsVertical: React.FC<IDescriptionsVerticalProps> = ({
	title,
	items,
	borderless,
	hideActions,
	columns,
	extraContent,
	onEditComponent,
	onEdit,
	onCopy,
}) => {
	const theme = useTheme();
	return (
		<DescriptionsContainer borderless={borderless}>
			<DescriptionsHeader>
				{typeof title === 'string' ? (
					<Heading2>{title}</Heading2>
				) : (
					title
				)}
				{!hideActions && (
					<DescriptionsActions>
						{typeof onCopy === 'function' && (
							<Button type="text" onClick={onCopy}>
								<FaRegCopy size={20} color={theme.primary} />
							</Button>
						)}
						{typeof onEdit === 'function' ? (
							onEditComponent ? (
								onEditComponent
							) : (
								<Button type="text" onClick={onEdit}>
									<BiSolidEditAlt
										size={26}
										color={theme.primary}
									/>
								</Button>
							)
						) : undefined}
					</DescriptionsActions>
				)}
			</DescriptionsHeader>
			<Descriptions
				layout="horizontal"
				bordered
				column={columns ?? 1}
				items={items}
			/>
			{extraContent && extraContent}
		</DescriptionsContainer>
	);
};

export default DescriptionsVertical;
