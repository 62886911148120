import { Button, Flex, Radio, Space } from 'antd';
import { Heading2 } from 'components/Heading2';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { AccountType } from 'types/Account';

interface IAccountTypeModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (accountType?: AccountType) => void;
	accountType?: AccountType;
	setAccountType: (accountType: AccountType) => void;
}

const AccountTypeModal = ({
	accountType,
	isOpen,
	onClose,
	onSave,
	setAccountType,
}: IAccountTypeModalProps) => {
	return (
		<Modal
			isOpen={isOpen}
			title="Configurações da conta"
			width={500}
			height={300}
			onClose={onClose}
		>
			<Heading2>Tipo de conta</Heading2>
			<br />
			<Radio.Group
				onChange={e => setAccountType(e.target.value)}
				value={accountType}
			>
				<Space direction="vertical">
					<Radio
						value={AccountType.ESCROW}
						style={{ marginBottom: '1rem' }}
					>
						Conta escrow
					</Radio>
					<Radio
						value={AccountType.PAYMENT}
						style={{ marginBottom: '1rem' }}
					>
						Conta livre
					</Radio>
				</Space>
			</Radio.Group>
			<Flex vertical>
				<Button
					onClick={() => onSave(accountType)}
					type="primary"
					style={{ marginTop: '2rem' }}
				>
					Salvar alterações
				</Button>
				<Button
					onClick={onClose}
					type="text"
					style={{ marginTop: '1rem' }}
				>
					<TextS color={theme.primary}>Cancelar</TextS>
				</Button>
			</Flex>
		</Modal>
	);
};

export default AccountTypeModal;
