import { Button, Checkbox, Divider, Flex } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Heading2 } from 'components/Heading2';
import { Modal } from 'components/Modal';
import { TextS } from 'components/Text';
import theme from 'theme/theme';
import { signMethodOptions } from '../TemplateConfig';
import { Body } from './styles';
const CheckboxGroup = Checkbox.Group;

interface ISignerMethodModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: () => void;
	options: string[];
	setOptions: (list: string[]) => void;
}

const SignerMethodModal = ({
	options,
	isOpen,
	onClose,
	onSave,
	setOptions,
}: ISignerMethodModalProps) => {
	const onChange = (list: string[]) => {
		setOptions(list);
	};

	return (
		<Modal
			isOpen={isOpen}
			title="Configurações da conta"
			width={500}
			height={300}
			onClose={onClose}
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Body>
				<Heading2>Método de assinatura</Heading2>
				<CheckboxGroup
					options={signMethodOptions}
					value={options}
					onChange={onChange}
				/>

				<Flex vertical>
					<Button
						onClick={onSave}
						type="primary"
						style={{ marginTop: '2rem', padding: '1rem' }}
					>
						<TextS color={theme.white}>Salvar alterações</TextS>
					</Button>
					<Button
						onClick={onClose}
						type="text"
						style={{ marginTop: '1rem' }}
					>
						<TextS color={theme.primary}>Cancelar</TextS>
					</Button>
				</Flex>
			</Body>
		</Modal>
	);
};

export default SignerMethodModal;
